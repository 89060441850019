import React, { useState, useRef } from "react";
import { useLifecycles } from "react-use";
import PropTypes from "prop-types";
import { Spinner, Heading, Box } from "@chakra-ui/react";
import cx from "classnames";

import { loadingTexts, TIME_PER_LOADING_TEXT } from "constants/loader-const";
import { getRandomElement } from "shared/helpers";

import "./loader.scss";

const Loader = ({ className, type, size, isVisible, isLoadingTextVisible }) => {
  const [loadingText, setLoadingText] = useState("");

  const classNames = {
    loaderTypes: {
      fullScreen: "loader--pos-fullscreen",
      fullPage: "loader--pos-fullpage",
      absolute: "loader--pos-absolute",
      relative: "loader--pos-relative"
    },
    loaderSizes: {
      small: "loader--size-small",
      large: "loader--size-large"
    }
  };

  const loaderType = classNames.loaderTypes[type] || "";
  const loaderSize = classNames.loaderSizes[size] || "";

  const intervalRef = useRef();

  useLifecycles(
    () => {
      setNewLoadingText();
      intervalRef.current = setInterval(setNewLoadingText, TIME_PER_LOADING_TEXT);
    },
    () => {
      // clears the interval reference on unmount to avoid calling `setInterval` forever
      resetIntervalRef();
    }
  );

  const setNewLoadingText = () => {
    const newLoadingText = getRandomElement(loadingTexts);
    setLoadingText(newLoadingText);
  };

  const resetIntervalRef = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  return (
    isVisible && (
      <div className={cx("loader", loaderType, loaderSize, className, { "loader--hidden": !isVisible })}>
        <div className="loader__spinner">
          <Box mb="2">
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.base" size="lg" />
          </Box>
          {isLoadingTextVisible && (
            <Heading as="h1" size="lg">
              {loadingText}
            </Heading>
          )}
        </div>
      </div>
    )
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["base", "fullScreen", "fullPage", "absolute", "relative"]),
  size: PropTypes.oneOf(["base", "small", "large"]),
  isVisible: PropTypes.bool,
  isLoadingTextVisible: PropTypes.bool
};

Loader.defaultProps = {
  className: "",
  type: "base",
  size: "base",
  isVisible: false,
  isLoadingTextVisible: false
};

export default Loader;
