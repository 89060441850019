import { ACTION_TYPES } from "constants/action-types";
import STATUS_TYPES from "constants/status-types";

const initialState = {
  status: STATUS_TYPES.INITIAL,
  data: {},
  related: {
    retailer: {
      status: STATUS_TYPES.INITIAL,
      data: {}
    },
    product: {
      status: STATUS_TYPES.INITIAL,
      data: {}
    }
  },
  updateReasons: [],
  listingExists: [] || false
};

export default function listingReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.LISTING.RESET:
      return initialState;
    case ACTION_TYPES.LISTING.SET_STATUS:
      return { ...state, status: action.payload };
    case ACTION_TYPES.LISTING.SET_LISTING_EXISTS:
      return { ...state, listingExists: action.payload };
    case ACTION_TYPES.LISTING.SET_DATA:
      return { ...state, data: action.payload };
    case ACTION_TYPES.LISTING.SET_RELATED_RETAILER_STATUS:
      return {
        ...state,
        related: { ...state.related, retailer: { ...state.related.retailer, status: action.payload } }
      };
    case ACTION_TYPES.LISTING.SET_RELATED_RETAILER_DATA:
      const prevResultsRetailer = state.related.retailer.data.results || [];
      return {
        ...state,
        related: {
          ...state.related,
          retailer: {
            ...state.related.retailer,
            data: {
              ...action.payload,
              results: [...prevResultsRetailer, ...action.payload.results]
            }
          }
        }
      };
    case ACTION_TYPES.LISTING.SET_RELATED_PRODUCT_STATUS:
      return { ...state, related: { ...state.related, product: { ...state.related.product, status: action.payload } } };
    case ACTION_TYPES.LISTING.SET_RELATED_PRODUCT_DATA:
      const prevResultsProduct = state.related.product.data.results || [];
      return {
        ...state,
        related: {
          ...state.related,
          product: {
            ...state.related.product,
            data: {
              ...action.payload,
              results: [...prevResultsProduct, ...action.payload.results]
            }
          }
        }
      };
    case ACTION_TYPES.LISTING.SET_UPDATE_REASONS:
      return { ...state, updateReasons: action.payload.actions.POST.modified_reason.choices };
    default:
      return state;
  }
}
