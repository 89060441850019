import {
  Button,
  Flex,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";

/**
 * @type {typeof ChakraModal}
 */
export const Modal = ({
  children,
  title,
  subtitle,
  footer,
  isOpen = false,
  closeable = true,
  onClose = () => {},
  bodyProps = {},
  contentProps = {},
  ...props
}) => {
  const handleOnClose = () => {
    if (closeable) {
      onClose();
    }
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={handleOnClose} {...props}>
      <ModalOverlay />
      <ModalContent my="auto" {...(contentProps ?? {})}>
        {title && (
          <ModalHeader>
            {title}
            {subtitle && (
              <Text textStyle="body2" fontFamily="body">
                {subtitle}
              </Text>
            )}
          </ModalHeader>
        )}
        {closeable && <ModalCloseButton />}
        <ModalBody {...(bodyProps ?? {})}>{children}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </ChakraModal>
  );
};

export const ModalActions = ({ onCancel, children, ...props }) => {
  return (
    <Flex justifyContent="space-around" w="full" {...props}>
      {children}
      {onCancel && (
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      )}
    </Flex>
  );
};
