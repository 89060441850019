export const TIME_PER_LOADING_TEXT = 4000;

export const loadingTexts = [
  "Max Retail was created by retailers who were tired of losing special orders and staring at unsold inventory.",
  "Buy more of the inventory you need, sell the inventory you don’t.",
  "Max Retail connects you with authorized retailers of your same brands.",
  "Did you know that 15-30% of all inventory you receive ends up - marked down?",
  "There’s $113 billion in unsold inventory in the U.S. alone, annually.",
  "We’re on a big mission here at Max Retail to help inventory turn faster.",
  "We love having you a part of our retailer community!",
  "We love your feedback! Use the little blue box in the corner to tell us what you think.",
  "Did you know we have a on-hand specialist? Use the little blue box in the corner for one-on-one inventory help.",
  "Buying from retailers on Max Retail gives you the flexibility to pick and choose your fill-ins without order minimums.",
  "Buying from retailers on Max Retail gives you the flexibility to try out styles with small quantities."
];
