import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  useDisclosure
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { Icon } from "ui-kit";
import { NoView, View } from "ui-kit/icon";

const PasswordInput = forwardRef((props, ref) => {
  const {
    label,
    labelProps = {},
    name,
    value,
    onChange,
    onBlur,
    isInvalid,
    isRequired,
    errorMessage,
    inputRightElement,
    helperText = null,
    required = false,
    defaultIsVisible = false,
    ...rest
  } = props;

  const { isOpen: isPasswordVisible, onToggle: togglePasswordVisible } = useDisclosure({
    defaultIsOpen: defaultIsVisible
  });
  const PasswordInputIcon = isPasswordVisible ? NoView : View;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
        {label}
      </FormLabel>
      <InputGroup>
        <ChakraInput
          ref={ref}
          pl="4"
          id={props.id ?? name}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          {...rest}
          type={isPasswordVisible ? "text" : "password"}
        />
        <InputRightElement>
          <Icon
            as={PasswordInputIcon}
            cursor="pointer"
            role="button"
            size="lg"
            aria-label={isPasswordVisible ? "Hide password" : "Show password"}
            aria-pressed={isPasswordVisible ? "true" : "false"}
            onClick={togglePasswordVisible}
          />
        </InputRightElement>
      </InputGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage paddingLeft="1.3em">{errorMessage}</FormErrorMessage>
    </FormControl>
  );
});

export default PasswordInput;
