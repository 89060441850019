import { FormNumber } from "ui-kit/form/number";
import { useSmartInput } from "ui-kit/form/smart/use-smart-input";

import FormInput from "../input/input";
import FormPassword from "../password-input/password-input";

export const SmartInput = ({ name, defaultValue, rules = {}, ...props }) => {
  const { field, fieldState } = useSmartInput({
    name,
    defaultValue,
    rules
  });

  return (
    <FormInput
      isRequired={!!rules.required}
      errorMessage={fieldState.error?.message}
      isInvalid={fieldState.error}
      {...field}
      {...props}
    />
  );
};

export const SmartPassword = ({ name, defaultValue, rules = {}, ...props }) => {
  const { field, fieldState } = useSmartInput({
    name,
    defaultValue,
    rules
  });

  return (
    <FormPassword
      isRequired={!!rules.required}
      errorMessage={fieldState.error?.message}
      isInvalid={fieldState.error}
      {...field}
      {...props}
    />
  );
};

export const SmartNumber = ({ name, defaultValue, rules = {}, ...props }) => {
  const { field, fieldState } = useSmartInput({
    name,
    defaultValue,
    rules
  });

  return (
    <FormNumber
      isRequired={!!rules.required}
      errorMessage={fieldState.error?.message}
      isInvalid={fieldState.error}
      {...field}
      {...props}
    />
  );
};
