import * as Sentry from "@sentry/react";

import authAPI from "api/auth-api";
import { ACTION_TYPES } from "constants/action-types";
import STATUS_TYPES from "constants/status-types";

import { clearCart } from "./cart";
import { setError, unsetError } from "./errors";
import { requestUserData, setStatus as setUserStatus, unsetData as unsetUserData } from "./user";

export function setToken(token) {
  return {
    type: ACTION_TYPES.AUTH.SET_TOKEN,
    payload: token
  };
}

export function unsetToken() {
  return {
    type: ACTION_TYPES.AUTH.UNSET_TOKEN
  };
}

export function setRetailer(retailerId) {
  return {
    type: ACTION_TYPES.AUTH.SET_RETAILER,
    retailerId
  };
}

export function unsetRetailer() {
  return {
    type: ACTION_TYPES.AUTH.UNSET_RETAILER
  };
}

export function setStatus(status) {
  return {
    type: ACTION_TYPES.AUTH.SET_STATUS,
    payload: status
  };
}

export function unsetHubspot() {
  return {
    type: ACTION_TYPES.AUTH.UNSET_HUBSPOT,
  };
}


export function doLogout() {
  return dispatch => {
    Sentry.setUser(null);

    dispatch(unsetToken());
    dispatch(unsetUserData());
    dispatch(unsetRetailer());
    dispatch(clearCart());
    dispatch(unsetHubspot());

    dispatch(setStatus(STATUS_TYPES.INITIAL));
    dispatch(setUserStatus(STATUS_TYPES.INITIAL));
  };
}

export function requestLogin(payload) {
  return dispatch => {
    dispatch(setStatus(STATUS_TYPES.PENDING));

    return authAPI.doLogin(payload).then(
      jsonPayload => {
        dispatch(setToken(jsonPayload));
        dispatch(setStatus(STATUS_TYPES.LOADED));
        dispatch(unsetError({ type: "auth" }));

        return dispatch(requestUserData(jsonPayload));
      },
      () => {
        dispatch(setStatus(STATUS_TYPES.FAILED));
        dispatch(unsetToken());
        // TODO: Paul - remove below comment after fixing
        // eslint-disable-next-line no-shadow

        // dispatch(setError({ type: "auth", errors: [response.data.detail] }));
        dispatch(
          setError({
            type: "auth",
            errors: [
              `For some reason that didn't work. If you are certain the password you entered is correct,
              there is likely an issue with your browser.
               Make sure you are using Chrome or call 347-509-7927 now for help.`
            ]
          })
        );
      }
    );
  };
}
