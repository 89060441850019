import { extendTheme } from "@chakra-ui/react";

import colors from "./colors";
import global from "./global-styles";
import textStyles from "./text-styles";

import {
  BadgeCount,
  Button,
  DashboardMetric,
  Icon,
  Input,
  ListItem,
  Modal,
  OrderMetric,
  Paper,
  Radio
} from "./components";

const theme = extendTheme({
  fonts: {
    // TODO: Look how to customize particular heading levels
    body: "CabinRegular, 'Times New Roman', sans-serif",
    bodyBold: "CabinMedium, 'Times New Roman', sans-serif",
    heading: "GilmerBold, 'Times New Roman', sans-serif",
    headingMedium: "GilmerMedium, 'Times New Roman', sans-serif"
  },

  colors,
  styles: {
    global
  },

  textStyles,
  fontSizes: {
    "2xs": "0.5rem"
  },

  components: {
    Form: {
      baseStyle: {
        helperText: {
          color: "gray.base",
          fontStyle: "italic",
          paddingLeft: "1.3em"
        }
      }
    },
    BadgeCount,
    Button,
    Icon,
    Input,
    Select: Input,
    NumberInput: Input,
    Paper,
    Radio,
    Progress: {
      baseStyle: {
        track: {
          bg: "rgba(0,0,0,0.1)"
        },
        filledTrack: {
          transitionProperty: "width"
        }
      }
    },
    Modal,
    DashboardMetric,
    OrderMetric,
    ListItem,
    Text: {
      defaultProps: {
        variant: "body",
        as: "p"
      }
    }
  }
});

export default theme;
