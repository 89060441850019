export const ORDERS_SIDE_BAR = {
  links: [
    {
      id: "sales",
      label: "My Sales",
      links: [
        {
          id: "pending_sales",
          label: "Pending",
          url: "/orders/pending/sales"
        },
        {
          id: "approved_sales",
          label: "Needs to Ship",
          url: "/orders/approved/sales"
        },
        {
          label: "Shipped",
          url: "/orders/shipped/sales"
        },
        {
          label: "Delivered",
          url: "/orders/delivered/sales"
        },
        {
          label: "Declined",
          url: "/orders/declined/sales"
        },
        {
          label: "Cancelled",
          url: "/orders/cancelled/sales"
        }
      ]
    },
    {
      id: "purchases",
      label: "My Purchases",
      links: [
        {
          id: "pending_purchases",
          label: "Pending",
          url: "/orders/pending/purchases"
        },
        {
          label: "Approved",
          url: "/orders/approved/purchases"
        },
        {
          label: "In Transit",
          url: "/orders/shipped/purchases"
        },
        {
          label: "Delivered",
          url: "/orders/delivered/purchases"
        },
        {
          label: "Declined",
          url: "/orders/declined/purchases"
        },
        {
          label: "Cancelled",
          url: "/orders/cancelled/purchases"
        }
      ]
    }
  ]
};

export const SETTINGS_SIDE_BAR = {
  links: [
    { key: "store", label: "Store Info", url: "/settings/store" },
    { key: "contact", label: "Contact Info", url: "/settings/contact" },
    { key: "payout", label: "Payout Info", url: "/settings/payout" },
    { key: "card", label: "Card On File", url: "/settings/card" },
    { key: "reset_password", label: "Reset Password", url: "/settings/reset_password" }
  ]
};

export const MY_BRANDS = {
  label: "My Brands",
  url: "/brands/my-brands"
};

export const BRANDS_SIDE_BAR = {
  links: [
    MY_BRANDS,
    {
      label: "Add Brand",
      url: "/brands/add-brand"
    },
    {
      label: "All Brands",
      url: "/brands/all-brands"
    }
  ]
};
