import React from "react";
import { Box } from "@chakra-ui/react";

import { Icon } from "ui-kit";
import { MRLogo } from "ui-kit/icon";

const Logo = () => <Icon icon={MRLogo} boxSize="1000px" opacity="5%" variant="active" />;

export const Background = props => (
  <Box
    position="fixed"
    top="0"
    left="0"
    w="full"
    h="full"
    overflow="hidden"
    aria-hidden="true"
    pointerEvents="none"
    {...props}
  >
    <Box pos="absolute" top="-400px" left="-500px">
      <Logo />
    </Box>
    <Box pos="absolute" bottom="-400px" right="-400px">
      <Logo />
    </Box>
  </Box>
);
