/**
 * Constants for instructing users how to contact Max Retail
 */

// Emails
export const EMAIL_PRODUCT_UPLOAD = "productupload@maxretail.com";
export const EMAIL_RETAILERS = "retailers@maxretail.com";
export const EMAIL_SUPPORT = "support@maxretail.com";
export const EMAIL_SUCCESS = "success@maxretail.com";

// Links to help articles
export const LINK_HELP_FAQ = "https://intercom.help/maxretail/en/collections/901340-help-faq";
export const LINK_LEGAL_INFORMATION = "https://intercom.help/maxretail/en/collections/901411-legal-information";
export const LINK_RETAILER_AGREEMENT = "https://intercom.help/maxretail/en/articles/1513907-retailer-agreement";
export const LINK_SELLER_TERMS = "https://intercom.help/maxretail/en/articles/5799086-seller-terms";
export const LINK_TERMS_CONDITIONS = "https://intercom.help/maxretail/en/articles/3666881-terms-conditions";

export const LINK_KNOWLEDGE_CENTER = "https://19540235.hs-sites.com/knowledge";

// Additional: phone, address, etc...
