import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

/**
 * @type {React.FC<React.ComponentProps<typeof Button>>}
 */
const ButtonLink = props => <Button as={Link} variant="primary" size="md" {...props} />;

export default ButtonLink;
