import { chakra } from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";

const CForm = chakra("form");

const Form = ({ children, form, onSubmit = () => {}, ...rest }) => {
  return (
    <FormProvider {...form}>
      <CForm {...rest} onSubmit={form.handleSubmit(onSubmit)}>
        {children}
      </CForm>
    </FormProvider>
  );
};

export default Form;
