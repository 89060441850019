import React from "react";
import { Box, Flex, Image, Text as CText } from "@chakra-ui/react";
import { ReferFriend as refer } from "static/images";
import { Link } from "react-router-dom";
import { ArrowRight } from "ui-kit/icon";

const NewFeaturePanel = () => {
  return (
    <Flex flexDir="row" shadow="0 -3pt 6pt hsla(0, 0%, 0%, 0.1)" borderTopRadius="10px">
      <Image src={refer} alt="Refer logo" width="123px" height="123px" borderTopRightRadius="8px" />
      <Box color="blue.darkest" padding="12px 26px 0px 20px" letterSpacing="0px">
        <CText fontSize="10px" lineHeight="12.5px" fontWeight="500" color="#6F7F98">
          NEW
        </CText>
        <CText fontSize="14px" lineHeight="20px" fontWeight="500" color="#2D3848">
          Refer a Friend
        </CText>
        <CText fontSize="14px" lineHeight="20px" fontWeight="500" color="#00252F" opacity="0.5">
          Grab Starbucks with a friend on us!
        </CText>
        <Link to="/refer-friend/">
          <Box display="flex" alignItems="center" marginBottom="6px" gap="2px" _hover="none">
            <CText fontSize="14px" fontWeight="500" color="#086883" textDecoration="underline">
              Refer Your Friends
            </CText>
            <Box as={ArrowRight} width="7.5px" height="6.43" />
          </Box>
        </Link>
      </Box>
    </Flex>
  );
};

export default NewFeaturePanel;
