import { WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Button } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

import { EmptyState, Icon } from "ui-kit";

/**
 * @type {import("@sentry/react/types/errorboundary").FallbackRender}
 */
function ErrorFallback({ error, componentStack, resetError }) {
  return (
    <EmptyState
      minH="525px"
      text="We're sorry, but something went wrong on our end."
      icon={<Icon as={WarningTwoIcon} boxSize="70px" variant="red" />}
      details={
        <Box align="center" mt="2">
          {process.env.NODE_ENV !== "production" && (
            <>
              <Box
                as="pre"
                bg="gray.100"
                p="4"
                borderRadius="4"
                textAlign="left"
                maxH="500px"
                overflowY="auto"
                textStyle="subtext"
              >
                <code>{error.message}</code>
                <code>{componentStack}</code>
              </Box>
            </>
          )}
          <Button variant="primary" mt="2" onClick={resetError}>
            Try again
          </Button>
        </Box>
      }
    />
  );
}

const ErrorCatcher = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={ErrorFallback}
      onReset={() => {
        // eslint-disable-next-line no-console
        console.warn("Resetting context due to error");
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorCatcher;
