import { theme as baseTheme } from "@chakra-ui/react";

export default {
  sizes: {
    xl: {
      control: { boxSize: "30px" }
    }
  },
  variants: {
    primary: props => ({
      control: {
        position: "relative",
        _checked: {
          _before: {
            ...baseTheme.components.Radio.baseStyle(props).control._checked._before,
            position: "absolute",
            width: "75%",
            height: "75%",
            borderRadius: "50%",
            background: "pirmary",
            border: "5px solid currentColor",
            boxShadow: "md",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1"
          }
        }
      }
    }),
    box: {
      container: {
        justifyContent: "center",
        padding: "4",
        borderRadius: "5",
        borderColor: "gray.base",
        borderWidth: "2px",

        _checked: {
          borderColor: "blue.300"
        }
      },
      control: {
        display: "none"
      },
      label: {
        _disabled: {
          opacity: 1,
          color: "gray.500"
        }
      }
    }
  }
};
