import { useSelector } from "react-redux";

import { getUserData } from "store/selectors/user";

export function useRequireRetailerGuard() {
  const user = useSelector(getUserData);

  // If user is not logged in, do nothing
  if (!user) return null;

  // If staff member or admin, do nothing
  if (user.is_staff || user.is_super) return null;

  return null;
}
