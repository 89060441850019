import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { httpBatchLink } from "@trpc/client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// Chakra-powered theme
import { UIKitProvider } from "ui-kit/provider";

import { API_ROUTES } from "../api/const";
import { trpc } from "./trpc";

// Initialize React Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // By default, react-query retries 3 times on failed query. Turning this feature off
      retry: false,
      // By default, react-query will refetch data on window focus. Turning this feature off
      refetchOnWindowFocus: false
    }
  }
});

const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: API_ROUTES.SELLERS,
      headers() {
        return {
          Authorization: localStorage.getItem("accessToken")
        };
      }
    })
  ]
});

export const BaseProviders = ({ children }) => {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <UIKitProvider>
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              {children}
              <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
            </QueryClientProvider>
          </trpc.Provider>
        </UIKitProvider>
      </QueryParamProvider>
    </Router>
  );
};
