import APIHandler from "api/api-handler";

import { API_ROUTES } from "./const";

const authAPI = {
  /**
   * @typedef {{
   *   email: string,
   *   password: string,
   * }} UserFields
   * @param {UserFields}
   * @return {MRAPI.Response<{
   *   access: string,
   *   refresh: string,
   * }>}
   */
  doLogin: ({ email, password }) => APIHandler.post({ url: API_ROUTES.AUTH.TOKEN, body: { email, password } }),
  /**
   * @param {string} token
   * @return {MRAPI.Response<{
   *   access: string
   * }>}
   */
  refreshToken: token => APIHandler.post({ url: API_ROUTES.AUTH.REFRESH, body: { refresh: token } }),
  /**
   * @param {UserFields} userFields
   * @return {MRAPI.Response<{
   *   email: string,
   *   id: number,
   * }>}
   */
  createUser: userFields => APIHandler.post({ url: API_ROUTES.AUTH.USERS, body: userFields })
};

export default authAPI;
