import React, { useEffect, useState } from "react";
import { useLocation } from "react-use";
import { Box, Flex, Radio, RadioGroup, Stack, Button } from "@chakra-ui/react";
import { DateTime } from "luxon";

import { StyleLayoutContainer } from "components/layout/side-nav-styles";
import { SUCCESS_MAX_RETAIL } from "constants/email";
import { Text, Heading } from "ui-kit";
import ModifierApi from "api/modifier-api";
import Loader from "components/shared/loader/loader";
import HeaderWithoutSideNav from "./header-without-sidenav";

const NO_DISCOUNT = "no discount";
const DISCOUNTS_OPTIONS = [
  { value: "50", label: "50%" },
  { value: "40", label: "40%" },
  { value: "30", label: "30%" },
  { value: NO_DISCOUNT, label: "Nope, I would prefer to hold onto this old inventory" }
];

const DiscountOldListingPage = () => {
  const location = useLocation();
  const [selectedDiscount, applyDiscount] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const discountParam = queryParams.get("discount");
    applyDiscount(DISCOUNTS_OPTIONS.map(d => d.value).includes(discountParam) ? discountParam : undefined);
  }, []);

  const confirmPriceDrop = async () => {
    try {
      setIsLoading(true);

      if (selectedDiscount === NO_DISCOUNT) {
        setIsLoading(false);
        return;
      }
      const yearAgo = DateTime.now()
        .minus({ years: 1 })
        .toFormat("yyyy-MM-dd");
      const numOfDiscount = Number(selectedDiscount);

      const response = await ModifierApi.createPricingModifier({
        date_range: {
          lower: "2020-01-01",
          upper: yearAgo
        },
        description: "discount on 1+ year merchandise",
        percentage_discount: numOfDiscount
      });

      setIsLoading(false);
      if (response.percentage_discount !== numOfDiscount) {
        applyDiscount(NO_DISCOUNT);
      }
    } catch (e) {
      setIsLoading(false);
      applyDiscount(NO_DISCOUNT);
    }
  };

  const basicRadioStyles = {
    borderColor: "#0A8EB1",
    backgroundColor: "#F2F4F4"
  };

  return (
    <>
      {isLoading && <Loader type="fullScreen" size="large" isVisible isLoadingTextVisible />}
      {!isLoading && (
        <StyleLayoutContainer>
          <HeaderWithoutSideNav />
          <Flex justify="center" align="center" textAlign="center" direction="column" w="100vw" h="100%">
            {isLoading === false && selectedDiscount !== NO_DISCOUNT && (
              <>
                <Flex justify="center" maxW="495" wrap="wrap" color="#00252F" spacing="20px" rowGap="20px">
                  <Text fontFamily="GilmerBold" fontSize="24px" lineHeight="29px">
                    Congratulations!
                  </Text>
                  <Text fontSize="14px" lineHeight="18px">
                    Your price drop has been applied to your inventory aged 1 year or older. Please make sure your
                    inventory is up to date. As always, Max Retail and our channel partners expect a 98% or higher order
                    approval rate.
                  </Text>
                  <Text fontSize="14px" lineHeight="18px">
                    <big>
                      <a href="/">Return to My Dashboard</a>
                    </big>
                  </Text>
                </Flex>
                <Text position="absolute" bottom="0px" fontSize="16px" lineHeight="18px" paddingBottom="20px">
                  Please contact&nbsp;<a href={`mailto:${SUCCESS_MAX_RETAIL}`}>{SUCCESS_MAX_RETAIL}</a>&nbsp;to make any
                  changes.
                </Text>
              </>
            )}
            {isLoading === false && selectedDiscount === NO_DISCOUNT && (
              <>
                <Flex justify="center" maxW="495" wrap="wrap" color="#00252F" spacing="20px" rowGap="20px">
                  <Text fontFamily="GilmerBold" fontSize="24px" lineHeight="29px">
                    No price changes for now!
                  </Text>
                  <Text fontSize="14px" lineHeight="18px">
                    You&apos;re holding off on price drops for your 1+ year old inventory, which is cool. Just a
                    reminder: price drops not only boost sales but give your products a fresh spotlight to sell.
                    Let&apos;s circle back soon!
                  </Text>
                  <Text fontSize="14px" lineHeight="18px">
                    <big>
                      <a href="/">Return to My Dashboard</a>
                    </big>
                  </Text>
                </Flex>
                <Text position="absolute" bottom="0px" fontSize="16px" lineHeight="18px" paddingBottom="20px">
                  Please contact&nbsp;<a href={`mailto:${SUCCESS_MAX_RETAIL}`}>{SUCCESS_MAX_RETAIL}</a>&nbsp;to make any
                  changes.
                </Text>
              </>
            )}
            {isLoading !== false && (
              <>
                <Flex justify="left" maxW="650px" wrap="wrap" direction="column">
                  <Heading>Let&apos;s make money moves!</Heading>
                  <Text pt="20px" body="4" color="#00252F">
                    Apply the discount below to your inventory aged 1 year or older. Click &rsquo;continue&rsquo; to
                    confirm the price drop, and please remember to approve and ship all orders promptly.
                  </Text>
                  <RadioGroup flexDir="column" pt="30px" onChange={applyDiscount} value={selectedDiscount}>
                    <Stack>
                      {DISCOUNTS_OPTIONS.map(({ value, label }) => (
                        <Radio key={value} sx={basicRadioStyles} size="lg" value={value}>
                          {label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                  <Box pt="30px">
                    <Button
                      textTransform="capitalize"
                      alignSelf="start"
                      size="md"
                      margin="auto 0"
                      paddingX="48px"
                      disabled={!selectedDiscount}
                      onClick={confirmPriceDrop}
                    >
                      Continue
                    </Button>
                  </Box>
                </Flex>
                <Text position="absolute" bottom="20px" w="100wv" textAlign="center">
                  <a href={`mailto:${SUCCESS_MAX_RETAIL}`}>{SUCCESS_MAX_RETAIL}</a>
                </Text>
              </>
            )}
          </Flex>
        </StyleLayoutContainer>
      )}
    </>
  );
};

export default DiscountOldListingPage;
