import { Text as ChakraText } from "@chakra-ui/react";

import theme from "../theme";

export const textLayers = Object.keys(theme.textStyles);

/**
 * @type {typeof ChakraText}
 */
export const Text = ({ textStyle = "body", as = "p", ...rest }) => (
  <ChakraText as={as} textStyle={textStyle} {...rest} />
);

/**
 * @type {typeof ChakraText}
 */
export const Heading = ({ textStyle = "h2", as = "h2", ...rest }) => (
  <ChakraText as={as} textStyle={textStyle} {...rest} />
);
