import PropTypes from "prop-types";

// style
import SideNavLayout from "components/layout/side-nav-layout";
import "./layout.scss";

const Layout = ({ children, showSidenav = true }) => {
  if (showSidenav) {
    return <SideNavLayout>{children}</SideNavLayout>;
  }
  return <div>{children}</div>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
