export default {
  baseStyle: () => ({
    minW: "120px",
    h: "85px",
    m: "2",
    py: "1",
    px: "2",
    borderWidth: "2px",
    borderRadius: "md",
    color: "blue.darkest",
    shadow: "md"
  }),
  variants: {
    positive: {
      borderColor: "green.light"
    },
    negative: {
      borderColor: "secondary.red"
    },
    neutral: {
      borderColor: "gray.400"
    },
    placeholder: {
      bg: "transparent linear-gradient(127deg, #f7fcfd 0%, #f2f5f7 100%) 0% 0% no-repeat padding-box;",
      borderWidth: "0",
      shadow: "none"
    }
  },

  defaultProps: {
    variant: "positive"
  }
};
