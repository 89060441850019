import { useToast } from "@chakra-ui/react";
import { useVersionService } from "hooks/version-service";
import { useEffect } from "react";

import { infoToast } from "shared/toast-service";

const Versioning = ({ children }) => {
  const shouldReload = useVersionService();
  const toast = useToast();
  const id = "reload-toast";

  useEffect(() => {
    if (shouldReload && !toast.isActive(id)) {
      infoToast({
        title: "New Max Retail Version Available",
        description: "The page will reload to ensure you are on the newest version.",
        duration: 5 * 1000, // 5 seconds
        onCloseComplete: () => {
          window.location.reload(true);
        },
        id
      });
    }
  }, [shouldReload]);

  return children;
};
export default Versioning;
