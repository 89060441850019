import React from "react";
import { Icon as CIcon, useStyleConfig } from "@chakra-ui/react";

/**
 * @type {typeof CIcon}
 */
const Icon = ({ icon, size, variant, ...props }) => {
  const styles = useStyleConfig("Icon", { size, variant });

  return <CIcon __css={styles} as={icon} {...props} />;
};

export default Icon;
