import { IconButton, Button } from "@chakra-ui/react";
import { merge } from "lodash";

const ARROW_STYLES = {
  padding: "0",
  color: "blue.darkest",
  bg: "gray.medium",

  _hover: {
    color: "#7f9196",
    bg: "gray.medium",
    _active: { bg: "#7f9196" },
    _disabled: { opacity: 0.4, bg: "gray.medium" }
  },

  _active: {
    bg: "blue.darkest",
    color: "white"
  },

  _disabled: {
    bg: "gray.medium",
    cursor: "not-allowed",
    opacity: 0.4
  },
  _focus: {
    boxShadow: "none"
  }
};

const NUMBER_STYLES = merge({}, ARROW_STYLES, {
  bg: "transparent",
  _disabled: { bg: "transparent" }
});

/**
 * @type {typeof IconButton}
 */
export const ArrowButton = props => (<IconButton sx={ARROW_STYLES} {...props} />);

/**
 *
 * @type {typeof Button}
 */
export const NumberButton = props => (<Button sx={NUMBER_STYLES} {...props} />);
