export default function mapUniqueListings(results) {
  const uniqueRegistry = {};
  const uniqueListings = [];

  results.forEach(listing => {
    if (!uniqueRegistry[listing.id]) {
      uniqueRegistry[listing.id] = true;
      uniqueListings.push(listing);
    }
  });

  return uniqueListings;
}
