import { Box, Image, SimpleGrid } from "@chakra-ui/react";
import { checkeredTile } from "static/images";
import { Modal, useContainerValue, withContainerBreakpoints } from "..";

/**
 *
 * @type React.FC<{
 *  images: string[]
 *  onClose: () => void
 *  isOpen: boolean
 *  columns?: number
 *  title: string
 * }>
 * @returns
 */
const ViewImageModal = withContainerBreakpoints(
  ({ title, images = [], onClose = () => {}, isOpen = false, columns }) => {
    const defaultedColumns = columns || useContainerValue({ md: 2, default: 1 });
    const size = useContainerValue({ lg: "6xl", default: "full" });

    return (
      <Modal
        title={title}
        contentProps={{ p: "4", py: "8", maxH: { md: "85vh" } }}
        bodyProps={{ overflowX: "auto" }}
        {...{ isOpen, onClose, size }}
      >
        <SimpleGrid gap="8" columns={defaultedColumns}>
          {images?.map((src, i) => (
            <Box key={i}>
              <Image src={src} borderRadius="md" cursor="pointer" backgroundImage={checkeredTile} />
            </Box>
          ))}
        </SimpleGrid>
      </Modal>
    );
  }
);

export default ViewImageModal;
