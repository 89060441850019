import { ACTION_TYPES } from "constants/action-types";
import { MAX_LISTINGS_PRICE } from "constants/listings";

const initialState = {
  filterArrays: {
    brands: [],
    sizes: [],
    category: []
  },
  priceRange: [0, MAX_LISTINGS_PRICE],
  status: ""
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FILTERS.SET_FILTERS:
      return { ...state, filterArrays: { ...state.filterArrays, ...action.payload } };
    case ACTION_TYPES.FILTERS.ALL_CATEGORIES:
      return { ...state, filterArrays: { ...state.filterArrays, category: [] } };
    case ACTION_TYPES.FILTERS.SET_PRICE_RANGE:
      return { ...state, priceRange: action.payload };
    case ACTION_TYPES.FILTERS.RESET_PRICE_RANGE:
      return { ...state, priceRange: initialState.priceRange };
    case ACTION_TYPES.FILTERS.SET_STATUS:
      return { ...state, status: action.payload };
    case ACTION_TYPES.FILTERS.CLEAR_FILTERS:
      return initialState;
    default:
      return state;
  }
}
