import { POPUP_TYPES } from "constants/popups.const.js";
import { ACTION_TYPES } from "constants/action-types";

class PopupService {
  attachToStore(store) {
    this.dispatch = store.dispatch;
  }

  makePopup(type, payload, onSubmit) {
    this.dispatch({ type: ACTION_TYPES.POPUP.ADD_POPUP, payload: { type, data: payload, onSubmit } });
  }

  makeCartPopup(payload) {
    this.makePopup(POPUP_TYPES.CART, payload);
  }
}

const popupService = new PopupService();

export default popupService;
