import React from "react";
import { ChakraProvider } from "@chakra-ui/react";

import { theme } from "ui-kit";

const UiThemeProvider = props => {
  return <ChakraProvider theme={theme} resetCSS {...props} />;
};

export default UiThemeProvider;
