import APIHandler from "api/api-handler";

import { API_ROUTES } from "./const";

const userAPI = {
  fetchUserData: () => APIHandler.get({ url: API_ROUTES.AUTH.ME }),

  /**
   * @param {string} search
   *
   * @returns {MRAPI.ListResponse<{ email: string, id: number, display_name: string }>}
   */
  searchUser: search => APIHandler.get({ url: API_ROUTES.AUTH.USERS, params: { search } }),

  /**
   *
   * @param {{
   *   password: string,
   *   email: string,
   *   first_name: string,
   *   last_name: string,
   *   celphone: string
   * }} body
   * @returns {MRAPI.Response<{ email: string, id: number }>}
   */
  createUser: body => APIHandler.post({ url: API_ROUTES.AUTH.USERS, body })
};

export default userAPI;
