import { Box, Button } from "@chakra-ui/react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import cx from "classnames";
import { POPUP_TYPES } from "constants/popups.const";

import "./popup.scss";

const Popup = ({
  type,
  data = null,
  children,
  footnote,
  removePopup,
  onSubmit,
  visibleAfterSubmit,
  buttonText = "OK",
  buttonProps = {},
  isCheckedOutModal,
  size,
  className,
  displayDefaultButton,
  isLoading,
  setIsLoading
}: {
  type?: string;
  data?: React.ReactElement;
  children?: React.ReactElement;
  footnote?: React.ReactElement;
  removePopup: () => void;
  onSubmit?: () => void;
  visibleAfterSubmit?: boolean;
  buttonText?: string;
  buttonProps?: {};
  isCheckedOutModal?: React.ReactElement;
  size?: string;
  className?: string;
  displayDefaultButton?: boolean;
  isLoading?: boolean;
  setIsLoading?: (set: boolean) => {};
}) => {
  const classes = cx("popup", className, {
    "popup--xl": size === "xl"
  });

  const onOkClick = () => {
    if (setIsLoading) {
      setIsLoading(true);
    }

    if (onSubmit) {
      onSubmit();

      if (visibleAfterSubmit) {
        return;
      }
    }

    removePopup();
  };

  const onClickModalOutside = () => {
    if (!isCheckedOutModal) {
      removePopup();
    }
  };

  return (
    <Box className={classes} onClick={onClickModalOutside}>
      <Box zIndex="modal" className="popup__content" onClick={e => e.stopPropagation()}>
        <button aria-label="Close popup" className="popup__close" type="button" onClick={removePopup}>
          <FontAwesomeIcon icon={faTimes} className="fa-md" color="#000" />
        </button>
        <div className="popup__message">{data ?? children}</div>
        {footnote && <div className="popup__footnote">{footnote}</div>}
        {displayDefaultButton && (
          <div className="popup__buttons">
            <Button size={isCheckedOutModal ? "lg" : "md"} onClick={onOkClick} isLoading={isLoading} {...buttonProps}>
              {buttonText}
            </Button>
            {type === POPUP_TYPES.ACTION && (
              <Button variant="tertiary" onClick={removePopup}>
                Cancel
              </Button>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
};

Popup.defaultProps = {
  isCheckedOutModal: false,
  displayDefaultButton: true
};

export default Popup;
