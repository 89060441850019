import { forwardRef, useMemo } from "react";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Tooltip,
  VisuallyHidden
} from "@chakra-ui/react";
import { Icon } from "ui-kit";
import { CircleInfo } from "ui-kit/icon";

const FormNumber = forwardRef((props, ref) => {
  const {
    label,
    labelProps = {},
    hideLabel = false,
    wrapperProps = {},
    name,
    helperText,
    value,
    onChange,
    onBlur,
    isInvalid,
    isRequired,
    isDisabled = false,
    required = false,
    errorMessage,
    inputHelpText,
    defaultValue,
    size = "md",
    ...rest
  } = props;

  const formLabel = useMemo(() => {
    if (hideLabel) {
      return (
        <VisuallyHidden>
          <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
            {label}
          </FormLabel>
        </VisuallyHidden>
      );
    }

    return (
      <HStack justify="space-between">
        <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
          {label}
        </FormLabel>
        {inputHelpText && (
          <Tooltip placement="auto" label={inputHelpText} hasArrow shouldWrapChildren>
            <Icon icon={CircleInfo} size="sm" d="block" variant="primary" cursor="pointer" mr="2" />
          </Tooltip>
        )}
      </HStack>
    );
  }, [props.id, name, hideLabel, inputHelpText, label]);

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      {formLabel}

      <NumberInput
        allowMouseWheel={false}
        clampValueOnBlur
        {...(wrapperProps ?? {})}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        size={size}
      >
        <NumberInputField ref={ref} pl="4" id={props.id ?? name} required={required} {...rest} />
        <NumberInputStepper w="2em">
          <NumberIncrementStepper pl=".65em" justifyContent="start" />
          <NumberDecrementStepper pl=".65em" justifyContent="start" />
        </NumberInputStepper>
      </NumberInput>

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage pl="1.3em">{errorMessage}</FormErrorMessage>
    </FormControl>
  );
});

export default FormNumber;
