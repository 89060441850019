const RADIUS = "5px";

export default {
  parts: ["group", "metric", "tag", "skeleton"],

  baseStyle: {
    group: {
      display: "flex",
      flexDirection: "row"
    },
    metric: {
      w: "150px",
      h: "60px",
      pt: "1",
      pb: "2",
      px: "2",
      color: "white",
      bg: "blue.darkest",
      "&:only-child": {
        borderWidth: "0",
        borderRadius: RADIUS
      }, // when there is only one metric
      "&:not(:only-child)": {
        borderRadius: "none",
        borderLeftWidth: "1px",
        borderRightWidth: "1px",
        borderColor: "white"
      }, // for all metrics if there are more than one
      "&:not(:only-child):first-of-type": {
        borderTopLeftRadius: RADIUS,
        borderBottomLeftRadius: RADIUS,
        borderRightWidth: "1px",
        borderColor: "white"
      }, // first metric in a group
      "&:not(:only-child):last-of-type": {
        borderTopRightRadius: RADIUS,
        borderBottomRightRadius: RADIUS,
        borderLeftWidth: "1px",
        borderColor: "white"
      } // last metric in a group
    },
    tag: {
      bg: "white",
      color: "green.base",
      boxShadow: "none",
      minH: "1.25rem",
      fontSize: "0.625rem"
    }
  },
  variants: {
    green: {
      metric: {
        borderColor: "green.dark",
        bg: "green.base",
        "&:only-child": {
          borderWidth: "2px"
        } // when there is only one metric
      }
    },
    placeholder: {
      metric: {
        bg: "transparent linear-gradient(127deg, #f7fcfd 0%, #f2f5f7 100%) 0% 0% no-repeat padding-box;",
        shadow: "none"
      },
      skeleton: {
        margin: "5px",
        height: ".875rem",
        bg: "white",
        borderRadius: "full"
      }
    }
  },

  defaultProps: {
    variant: "base"
  }
};
