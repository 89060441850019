import { ACTION_TYPES } from "constants/action-types";
import STATUS_TYPES from "constants/status-types";

const initialState = {
  status: STATUS_TYPES.INITIAL,
  data: []
};

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.CATEGORIES.SET_STATUS:
      return { ...state, status: action.payload };
    case ACTION_TYPES.CATEGORIES.SET_DATA:
      return { ...state, data: action.payload };
    default:
      return state;
  }
}
