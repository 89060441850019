function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false
  };

  const opt = {
    ...defaultOptions,
    ...options
  };

  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
  });
}

export const DEFAULT_LOCALE = getBrowserLocales() ?? ["en-US", "en"];

export function formatNumber(num, { locale = DEFAULT_LOCALE, options = {} } = {}) {
  const formatter = new Intl.NumberFormat(locale, options);
  const value = formatter.format(num);

  if (value === "NaN") return null;
  return value;
}
