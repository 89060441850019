import { useToken } from "@chakra-ui/react";
import { forwardRef } from "react";
import { theme } from "ui-kit";

import RSSelect from "react-select";
import RSAsync from "react-select/async";
import RSAsyncCreatable from "react-select/async-creatable";
import RSSelectCreatable from "react-select/creatable";

const useColors = () => {
  const [primary, primary25, primary50, primary75] = useToken("colors", [
    "blue.600",
    "blue.50",
    "blue.200",
    "blue.400"
  ]);

  return {
    primary,
    primary25,
    primary50,
    primary75
  };
};

// Build's theme structure that is specific to react-select
const reactSelectTheme = ({ colors }) => targetTheme => ({
  ...targetTheme,
  colors: {
    ...targetTheme.colors,
    ...colors
  }
});

const getCustomStyles = ({ borderRadius, styles }) => {
  const radius = {
    full: "9999px",
    sm: "4px",
    none: "0px",
    md: "9px"
  };

  const fontSizeStyle = original => ({
    ...original,
    fontSize: "14px"
  });

  const borderRadiusStyle = original => ({
    ...original,
    borderRadius: radius[borderRadius] ?? "21px",
    paddingLeft: "6px",
    borderColor: theme.colors.gray.base
  });

  return {
    ...styles,
    valueContainer: fontSizeStyle,
    option: fontSizeStyle,
    control: borderRadiusStyle
  };
};

/**
 * @type {typeof RSSelect}
 */
export const Select = forwardRef((props, ref) => {
  const colors = useColors();

  const themed = reactSelectTheme({ colors });

  return <RSSelect theme={themed} ref={ref} styles={getCustomStyles(props)} {...props} />;
});

/**
 * @type {typeof RSSelectCreatable}
 */
export const SelectCreatable = forwardRef((props, ref) => {
  const colors = useColors();

  const themed = reactSelectTheme({ colors });

  return <RSSelectCreatable theme={themed} ref={ref} styles={getCustomStyles(props)} {...props} />;
});

/**
 * @type {typeof RSAsync}
 */
export const SelectAsync = forwardRef((props, ref) => {
  const colors = useColors();

  const themed = reactSelectTheme({ colors });

  return <RSAsync theme={themed} ref={ref} styles={getCustomStyles(props)} {...props} />;
});

/**
 * @type {typeof RSAsyncCreatable}
 */
export const SelectAsyncCreatable = forwardRef((props, ref) => {
  const colors = useColors();

  const themed = reactSelectTheme({ colors });

  return <RSAsyncCreatable theme={themed} ref={ref} styles={getCustomStyles(props)} {...props} />;
});
