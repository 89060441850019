import { Box, Flex, Link as CLink, Tooltip } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { LockIcon } from "@chakra-ui/icons";
import { NavLink } from "react-router-dom";

import PopupManager from "components/popup-manager";
import { POPUP_TYPES } from "constants/popups.const";
import { getCartTotalUnits } from "store/selectors/cart";
import { Icon } from "ui-kit";
// @ts-ignore
import { Cart } from "ui-kit/icon";

export const SideNavLink = ({ children, icon, external, isLocked = false, tooltipDescription = "", ...rest }: any) => {
  const handleClick = e => {
    if (isLocked) {
      e.preventDefault();
    }
  };

  return (
    <Tooltip label={tooltipDescription} placement="top" hasArrow>
      <Box as="li" w="full" listStyleType="none" role="group">
        <Box
          as={external ? CLink : NavLink}
          onClick={handleClick}
          borderRadius="lg"
          color={isLocked ? "#9FADC1" : "gray.900"}
          py="2"
          px="3"
          d="inline-flex"
          alignItems="center"
          w="full"
          textDecor="none"
          _hover={{
            bg: isLocked ? "#9FADC1" : "gray.100",
            color: isLocked ? "#9FADC1" : "gray.800",
            textDecor: "none"
          }}
          sx={{
            "&.active": {
              bg: "gray.100"
            }
          }}
          {...rest}
        >
          {isLocked ? (
            <LockIcon
              mr="2"
              mb="3px"
              sx={{ ".active &": { color: "primary" } }}
              _groupHover={{
                color: isLocked ? "#9FADC1" : "primary"
              }}
              color={isLocked ? "#9FADC1" : "primary"}
            />
          ) : (
            icon && (
              <Icon
                as={icon}
                boxSize="5"
                mr="2"
                mb="3px"
                sx={{ ".active &": { color: "primary" } }}
                _groupHover={{
                  color: isLocked ? "#9FADC1" : "primary"
                }}
              />
            )
          )}
          <span>{children}</span>
        </Box>
      </Box>
    </Tooltip>
  );
};

export const SideNavCartLink = () => {
  const cartUnits = useSelector(getCartTotalUnits);

  if (!cartUnits) return null;

  return (
    <SideNavLink to="/cart" role="group">
      <Box d={{ base: "none", lg: "initial" }}>
        <PopupManager filters={[POPUP_TYPES.CART]} />
      </Box>
      <Flex d="inline-flex" w="full" align="center">
        <Box as="span" mr="auto">
          Cart ({cartUnits})
        </Box>
        <Icon as={Cart} sx={{ ".active &": { color: "primary" } }} _groupHover={{ color: "primary" }} />
      </Flex>
    </SideNavLink>
  );
};
