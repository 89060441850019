import APIHandler from "api/api-handler";

import { API_ROUTES } from "./const";

const modifierApi = {
  /**
   *
   * @param {{
   *   date_range: object,
   *   description: string,
   *   percentage_discount: number
   * }} body
   * @returns {MRAPI.Response<{ date_range: object, description: string, percentage_discount: number,
   * status: number}>}
   */
  createPricingModifier: body => APIHandler.post({ url: API_ROUTES.PRICING_MODIFIER, body })
};

export default modifierApi;
