const RADIUS = "5px";

export default {
  parts: ["wrapper", "body", "label", "skeleton"],

  baseStyle: {
    wrapper: {
      w: "full",
      px: "2",
      color: "black",
      bg: "white"
    },
    label: {
      minH: "3.25rem",
      w: "fit-content",
      pl: "2",
      pr: "6",
      py: "1",
      mb: "-1",
      backgroundColor: "#eef0f0",
      borderWidth: "1px",
      borderColor: "gray.base",
      borderRadius: "10px 10px 0px 0px"
    },
    body: {
      w: "full",
      h: "5rem",
      p: "4",
      justifyContent: "space-between",
      alignItems: "center",
      borderWidth: "1px",
      borderColor: "primary",
      borderRadius: RADIUS,
      boxShadow: "0px 3px 6px #00000029",
      backgroundColor: "white"
    }
  },
  variants: {
    red: {
      label: {
        backgroundColor: "red.darkest",
        borderColor: "red.dark",
        color: "white"
      },
      body: {
        borderColor: "red.dark"
      }
    },
    primary: {
      label: {
        background: "primary",
        borderColor: "primary",
        color: "white"
      },
      body: {
        borderColor: "white"
      }
    },
    lightBlue: {
      label: {
        background: "blue.150",
        borderColor: "blue.base",
        color: "black"
      }
    },
    white: {
      body: {
        borderColor: "white"
      }
    },
    placeholder: {
      body: {
        border: "none"
      },
      skeleton: {
        margin: "1",
        minH: "4",
        bg: "gray.light",
        borderRadius: RADIUS
      }
    }
  },

  defaultProps: {
    variant: "default"
  }
};
