import React from "react";
import { Box, useStyleConfig } from "@chakra-ui/react";

/**
 * @type {typeof Box}
 */
const Paper = ({ size, ...rest }) => {
  const styles = useStyleConfig("Paper", { size });

  return <Box __css={styles} {...rest} />;
};

export default Paper;
