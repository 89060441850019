export default {
  variants: {
    primary: {
      header: {
        mb: 3,
        fontSize: "2.5rem",
        Text: {
          fontFamily: "body"
        }
      },
      closeButton: {
        top: 4,
        insetEnd: 4
      },
      body: {
        px: 6,
        pt: 0,
        fontSize: "md"
      },
      footer: {
        p: 6
      }
    }
  },
  defaultProps: {
    variant: "primary",
    size: "lg"
  }
};
