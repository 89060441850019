import { connect } from "react-redux";
import { requestUserDataIfToken } from "store/actions/user";

import { getUserData, getUserDataStatus } from "store/selectors/user";
import { getIsLoggedIn } from "store/selectors/auth";
import { getListingsStatus } from "store/selectors/listings";

import AppRouter from "./app-router";

const mapStateToProps = state => {
  return {
    user: getUserData(state),
    userStatus: getUserDataStatus(state),
    isLoggedIn: getIsLoggedIn(state),
    listingsStatus: getListingsStatus(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doGetUserData: () => {
      dispatch(requestUserDataIfToken());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
