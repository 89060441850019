import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  Stack
} from "@chakra-ui/react";
import { forwardRef } from "react";

/**
 * @type {typeof ChakraRadio}
 */
export const Radio = forwardRef((props, ref) => {
  const { label, labelProps = {}, name, value, onChange, isInvalid, ...rest } = props;

  return (
    <ChakraRadio
      ref={ref}
      id={props.id ?? name}
      name={name}
      value={value}
      onChange={onChange}
      borderColor="primary"
      {...rest}
    >
      <FormLabel cursor="pointer" htmlFor={props.id ?? name} mt="2" fontSize="sm" fontFamily="bodyBold" {...labelProps}>
        {label}
      </FormLabel>
    </ChakraRadio>
  );
});

/**
 * @type {typeof ChakraRadioGroup}
 */
export const RadioGroup = forwardRef((props, ref) => {
  const { name, value, onChange, isInvalid, isRequired, errorMessage, children, ...rest } = props;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <Stack spacing={{ sm: 1, md: 5 }} direction="row">
        <ChakraRadioGroup ref={ref} id={props.id ?? name} name={name} value={value} onChange={onChange} {...rest}>
          {children}
        </ChakraRadioGroup>
      </Stack>
      {isInvalid && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
});
