import { Flex } from "@chakra-ui/react";
import { Heading, Paper } from "..";

const EmptyState = ({ icon, text, details, ...props }) => {
  return (
    <Paper pos="relative" shadow="lg" w="full" p="4" {...props}>
      <Flex direction="column" minH="250px" gap="4" justify="center" align="center">
        {icon}
        <Heading as="h3" textStyle="h3">
          {text}
        </Heading>
        {details}
      </Flex>
    </Paper>
  );
};

export default EmptyState;
