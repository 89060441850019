import { useController, useFormContext } from "react-hook-form";

export const useSmartInput = ({ name, rules = {}, defaultValue, shouldUnregister = true } = {}) => {
  const { control } = useFormContext();

  const { field, fieldState } = useController({
    control,
    name,
    defaultValue,
    rules,
    shouldUnregister
  });

  return { field, fieldState };
};
