import { mode } from "@chakra-ui/theme-tools";

// eslint-disable-next-line no-unused-vars
export default props => ({
  "html, body": {
    color: mode("blue.darkest", "white")(props),
    fontSize: { base: "14px", sm: "16px" } // TODO: Eventually switch this to 110%?
  },

  body: {
    "& a": {
      color: "primary"
    },
    "*::placeholder": {
      color: "rgba(0, 0, 0, 0.8)"
    }
  }
});
