import { Box, Button, FormControl, FormErrorMessage, FormLabel, Text, useToken } from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  display: "flex",
  justifyContent: "center",
  borderWidth: "1px",
  borderStyle: "dashed",
  borderRadius: "5px",
  padding: "2em",
  fontSize: "14px",
  transition: "border .24s ease-in-out"
};

const MAX_FILES = 10;

const FileUpload = forwardRef((props, ref) => {
  const {
    label,
    labelProps = {},
    children,
    accept,
    maxFiles = MAX_FILES,
    isInvalid,
    isRequired,
    errorMessage,
    onUploadFiles = () => {},
    ...rest
  } = props;

  const [blueBase, secondaryRed, grayBase] = useToken("colors", ["blue.base", "secondary.red", "gray.base"]);

  const focusedStyle = { borderColor: blueBase };
  const acceptStyle = { borderColor: blueBase };
  const rejectStyle = { borderColor: secondaryRed };

  const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept,
    maxFiles,
    onDrop: accepted => {
      onUploadFiles(accepted);
    }
  });

  const style = useMemo(
    () => ({
      borderColor: grayBase,
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const filesButtonText = useMemo(() => {
    if (acceptedFiles.length === 1) {
      return acceptedFiles[0].name;
    }
    if (acceptedFiles.length > 1) {
      return `Browse Files (${acceptedFiles.length} file${acceptedFiles.length > 1 ? "s" : ""} added)`;
    }

    return `Browse Files`;
  }, [acceptedFiles]);

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired} {...rest}>
      <FormLabel fontSize="sm" fontFamily="CabinMedium" {...labelProps}>
        {label}
      </FormLabel>
      <Box {...getRootProps({ style })}>
        <input type="file" hidden {...getInputProps()} ref={ref} />
        <Box textAlign="center">
          <Text mb="2">{children}</Text>
          <Button variant="shadow" textTransform="initial" size="xs" color="blue.base">
            {filesButtonText}
          </Button>
        </Box>
      </Box>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
});

export default FileUpload;
