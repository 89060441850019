import { connect } from "react-redux";

import { getPopups } from "store/selectors/popup";

import { removePopup } from "store/actions/popup";

import PopupManager from "./popup-manager";

const mapStateToProps = state => {
  return {
    popups: getPopups(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doRemovePopup: popupId => {
      dispatch(removePopup(popupId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupManager);
