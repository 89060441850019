import { ACTION_TYPES } from "constants/action-types";
import { isEmpty } from "lodash";

export function sessionStorageMiddleware({ getState }) {
  return next => action => {
    const { user } = getState();
    const isStaff = user.data.is_staff;

    if (action.type === ACTION_TYPES.AUTH.SET_RETAILER && isStaff) {
      sessionStorage.setItem("retailerId", action.retailerId);
      window.location.reload();
    } else if (action.type === ACTION_TYPES.AUTH.UNSET_RETAILER) {
      sessionStorage.setItem("retailerId", "");

      // Only reload the page if not logging out
      if (!isEmpty(user.data)) {
        window.location.reload();
      }
    }

    return next(action);
  };
}
