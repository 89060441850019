import { notificationRoseBg } from "static/images";
import { useContainerValue, withContainerBreakpoints } from "ui-kit";
import { MOBILE_NAV_HEIGHT } from "components/layout/constants";
/*
Scheduling settings

ALERT_START_DATE and ALERT_END_DATE can be set to a Date object, e.g.
    const ALERT_START_DATE = new Date("Nov 10, 2023 00:00:00 EST");
or null, meaning no time restriction should be applied.

any combination of Date() objects and null is supported (e.g. start date with no end date, etc).
ENABLE_SITEWIDE_ALERT must be true for alert to ever be displayed, regardless of scheduling settings.
*/

const ENABLE_SITEWIDE_ALERT = true;
const ALERT_START_DATE = new Date("Tuesday, July 2, 2024 02:00 EST");
const ALERT_END_DATE = new Date("Wednesday, July 3, 2024 23:59 EST");

const SITEWIDE_ALERT_MESSAGE = `
<span style="color: #fff; text-shadow: 1px 1px 2px #a66;">
  Reminder: U.S. Post Offices will be closed Thursday, July 4th for Independence Day. If you need to pause your account 
  during this time to avoid order declines, please reach out to <a style="color: #cff" href="mailto:success@maxretail.com">success@maxretail.com</a>.
</span>
`;

const currentDate = new Date();
const isDuringScheduledTimeRange =
  (!ALERT_START_DATE || currentDate >= ALERT_START_DATE) && (!ALERT_END_DATE || currentDate <= ALERT_END_DATE);

const BREAKPOINTS = {
  lg: 992
};

/* eslint-disable react/no-danger */
const SitewideAlertBanner = ({ isLoggedIn = false }) => {
  const h1Style = useContainerValue({
    lg: { marginLeft: isLoggedIn ? "300px" : "0" },
    default: { marginLeft: "0", color: "#fff" }
  });
  const wrapperStyle = useContainerValue({
    lg: {
      padding: "15px",
      marginTop: 0,
      backgroundImage: `url(${notificationRoseBg})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    },
    default: {
      marginTop: isLoggedIn ? MOBILE_NAV_HEIGHT : "0", // offset mobile menu
      padding: "15px",
      backgroundImage: `url(${notificationRoseBg})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    }
  });
  const alertBanner = (
    <div style={wrapperStyle}>
      <h1 style={h1Style} dangerouslySetInnerHTML={{ __html: SITEWIDE_ALERT_MESSAGE }} />
    </div>
  );

  const noAlertBumpDiv = (
    <div
      style={useContainerValue({
        lg: { minHeight: 0 },
        default: { minHeight: MOBILE_NAV_HEIGHT }
      })}
    />
  );
  return ENABLE_SITEWIDE_ALERT && isDuringScheduledTimeRange ? alertBanner : noAlertBumpDiv;
};

export default withContainerBreakpoints(SitewideAlertBanner, BREAKPOINTS);
