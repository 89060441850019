import { LISTINGS_FILTER_STATUSES } from "constants/listings";
import { trim } from "lodash";

export const { VITE_API_URL: API_URL, VITE_SELLERS_API_URL, VITE_SHOPIFY_APP_ID: SHOPIFY_APP_ID } = import.meta.env;
export const API_VERSION = "api/v1";
export const BLOG_BASE_URL = "//www.maxretail.com";

// ALWAYS USE A TRAILING SLASH
const apiTrim = str => trim(str, "/");
const apiPath = (url, base) => pathname => {
  const fullpath = [url, base, pathname].map(apiTrim).join("/");
  return `${fullpath}/`;
};

export const apiAuthPath = apiPath(API_URL, `/auth`);
export const apiV1Path = apiPath(API_URL, API_VERSION);

export const API_ROUTES = {
  ADDRESS_VALIDATE: apiV1Path("/address/validate"),
  AUTH: {
    ME: apiAuthPath(`/me`),
    USERS: apiAuthPath(`/users`),
    TOKEN: apiAuthPath(`/token`),
    REFRESH: apiAuthPath(`/token/refresh`)
  },
  BRANDS: apiV1Path(`/retailerbrand`),
  ALL_BRANDS: apiV1Path(`/brand`),
  BRANDS_LISTED: apiV1Path(`/listedbrands`),
  generate_LISTED_BRAND: id => apiV1Path(`/listedbrands/${id}`),
  BRANDS_REQUESTED: apiV1Path(`/listingrequestedbrands`),
  ADD_BRAND: apiV1Path(`/retailerbrandrequest`),

  BLOG_FEED: apiV1Path(`/blog/internal`),

  CONNECTION: {
    REQUESTS: apiV1Path(`/connections/requests`),
    REQUEST_BRANDS: apiV1Path(`/connections/request-brands`),
    MODIFICATION: apiV1Path(`/connections/modifications`),
    DYNAMIC_DESCRIPTIONS: apiV1Path(`/connections/ai/description-assist/`),
    VERIFICATION_ERRORS: apiV1Path(`/connections/verification-errors/`),
    ERROR_REPORT: retailerId => apiV1Path(`/connections/error-report/${retailerId}`)
  },
  PRODUCTS: apiV1Path(`/product`),
  generate_PRODUCT: id => apiV1Path(`/product/${id}`),
  generate_PRODUCT_CATEGORY: id => apiV1Path(`/productcategory/${id}`),
  PRODUCT_CATEGORIES: apiV1Path(`/productcategories`),
  NORMALIZED_SIZES: apiV1Path(`/normalizedsizes`),
  PRODUCT_VARIATION: apiV1Path(`/productvariation`),
  FLAGS: apiV1Path(`/flags`),
  PIM_VARIANTS: apiV1Path(`/pim/variants/`),
  generate_PIM_VARIANT_INSTANCE: id => apiV1Path(`/pim/variants/${id}`),
  generate_APPROVE_PIM_VARIANT: id => apiV1Path(`/pim/variants/${id}/approve/`),
  generate_DELETE_PIM_IMAGE: id => apiV1Path(`/pim/images/${id}/`),
  generate_ADD_PIM_IMAGE: id => apiV1Path(`/pim/variants/${id}/add_image/`),
  generate_WHITEN_PIM_IMAGE: id => apiV1Path(`/pim/images/${id}/whiten/`),
  generate_TAG_PIM_IMAGE: id => apiV1Path(`/pim/images/${id}/tag/`),
  PIM_SEARCH: apiV1Path(`/pim/search`),
  LISTING_CONDITIONS: apiV1Path(`/haspreloved`),
  PRODUCTATTRIBUTE_SCHEMAS: apiV1Path(`/productattribute/`),
  PRICE_MARKDOWNS: apiV1Path(`/pricemarkdown/`),
  SIZES: apiV1Path(`/productsize`),
  generate_SIZE_LABEL: id => apiV1Path(`/sizelabel/${id}`),
  SIZES_AVAILABLE: apiV1Path(`/productsize/available`),
  LISTINGS: apiV1Path(`/listing`),
  PRODUCT_LISTINGS: apiV1Path(`/productlisting`),
  LISTINGS_REQUESTED: apiV1Path(`/listingrequest`),
  LISTINGS_MINE: apiV1Path(`/mylistings`),
  LISTINGS_REQUESTED_MINE: apiV1Path(`/mylistingrequests`),
  ORDERS: apiV1Path(`/orders`),
  generate_ORDER: id => apiV1Path(`/orders/${id}`),
  // ORDERS_SUMMARY: apiV1Path(`/orders/summary`),
  ORDERS_STATUS_SUMMARY: apiV1Path(`/orders/status-summary`),
  ORDERS_LIFE_TIME_VALUE_METRICS: apiV1Path(`/orders/life-time-value-metrics`),
  ORDERS_FEED: apiV1Path(`/orders-feed`),
  PACKINGSLIP: apiV1Path(`/packingsliporders`),
  GET_PACKINGSLIP_PDF: id => apiV1Path(`/get-packing-slip/${id}`),
  generate_PACKINGSLIP: id => apiV1Path(`/packingsliporders/${id}`),
  PHONE_VERIFICATION: apiV1Path(`/phoneverification`),
  RETAILER: apiV1Path(`/retailer`),
  RTR_UPLOAD_FILE: apiV1Path(`/connections/rtr-batch`),
  RTR_PROCESS_FILE: id => apiV1Path(`/connections/rtr-batch/${id}/process`),
  MR_GENERIC_UPLOAD_FILE: apiV1Path(`/connections/mr-generic-batch`),
  MR_GENERIC_PROCESS_FILE: id => apiV1Path(`/connections/mr-generic-batch/${id}/process`),
  generate_RETAILER: id => apiV1Path(`/retailer/${id}`),
  LIGHTSPEED_R_SERIES: retailerId => apiV1Path(`/retailer/${retailerId}/lightspeed_r_series/`),
  CARDS: {
    LIST: apiV1Path(`/cards`),
    INTENT: apiV1Path(`/cards/intent`),
    ACCOUNTLINK: apiV1Path(`/cards/accountlink`),
    PAYOUTCONFIG: apiV1Path(`/cards/needspayoutconfig`)
  },
  FORM_URLS: {
    STORE_INFO: apiV1Path(`/create-retailer`),
    generate_STORE_INFO: id => apiV1Path(`/create-retailer/${id}`),
    CONTACT_INFO: apiV1Path(`/update-retailer-contact`),
    generate_CONTACT_INFO: id => apiV1Path(`/update-retailer-contact/${id}`),
    RESET_PASSWORD: apiAuthPath(`/reset_password`),
    UPDATE_PASSWORD: apiAuthPath(`/set_password`),
    CHANGE_PASSWORD: apiAuthPath(`/reset_password_confirm`)
  },
  CART: {
    TRANSACTION_BUY: apiV1Path(`/buytransaction`),
    TRACKSHIPMENT: apiV1Path(`/trackshipment`),
    PROCESSPOSHMARKLABELPDF: apiV1Path(`/process-poshmark-label-pdf`),
    SETUP_DROPSHIP_TX: apiV1Path(`/setupdropshiptransaction`),
    CARDS: apiV1Path(`/cards`),
    generate_PAYMENT_INTENT: transactionId => apiV1Path(`/buytransaction/${transactionId}/intent`),
    generate_CHECKOUT_INTENT: transactionId => apiV1Path(`/buytransaction/${transactionId}/checkout`),
    // Deprecated. Stripe transactions are completed via webhooks.
    generate_TRANSACTION_COMPLETE: transactionId => apiV1Path(`/buytransaction/${transactionId}/completecheckout`),
    generate_SALES_CHANNEL_CHECKOUT_COMPLETE: transactionId =>
      apiV1Path(`/buytransaction/${transactionId}/sales_channel_checkout`),
    generate_ADD_PACKING_SLIP: transactionId => apiV1Path(`/buytransaction/${transactionId}/add_packing_slip/`)
  },
  generate_ORDER_HISTORY: orderId => apiV1Path(`/orders/${orderId}/history`),
  generate_ORDER_APPROVE: orderId => apiV1Path(`/buytransaction/${orderId}/approve`),
  generate_ORDER_CANCEL: orderId => apiV1Path(`/buytransaction/${orderId}/cancel_shipment`),
  generate_ORDER_DECLINE: (orderId, ownRole) =>
    apiV1Path(`/buytransaction/${orderId}/${ownRole}_decline${ownRole === "provider" ? "" : "_checkout"}`),
  generate_MODIFIED_ORDER_APPROVE: orderId =>
    apiV1Path(`/buytransaction/${orderId}/receiver_approve_modified_purchase`),
  generate_REQUEST_BRAND: retailerId => apiV1Path(`/retailer/${retailerId}/add_brand_by_name`),
  generate_UPLOAD_FILE: file => `${apiV1Path("/upload")}?filename=${file.name}&content-type=${file.type}`,
  PRICING_MODIFIER: apiV1Path(`/pricingmodifier`),
  SELLERS: VITE_SELLERS_API_URL,
  IMAGEVERIFICATION: {
    TAGGED_IMAGES: apiV1Path(`/inference/tagged-images`),
    IMAGE_TAGS: apiV1Path(`/inference/image-tags`),
    CORRECT_TAG: imageId => apiV1Path(`/inference/tagged-images/${imageId}/correct/`),
    IMAGE: imageId => apiV1Path(`/inference/tagged-images/${imageId}/`)
  }
};

export const LISTING_DEFAULTS = {
  ordering: "created",
  offset: 0,
  minPriceFilter: undefined,
  maxPriceFilter: undefined,
  status: LISTINGS_FILTER_STATUSES.ACTIVE
};
