import { ACTION_TYPES } from "constants/action-types";

export function addPopup(payload) {
  return {
    type: ACTION_TYPES.POPUP.ADD_POPUP,
    payload
  };
}

export function removePopup(payload) {
  return {
    type: ACTION_TYPES.POPUP.REMOVE_POPUP,
    payload
  };
}

export function clearPopup(payload) {
  return {
    type: ACTION_TYPES.POPUP.CLEAR_POPUP,
    payload
  };
}
