export default {
  primary: "#0a8eb1",
  secondary: {
    red: "#ff6767",
    yellow: "#f8d823"
  },
  tan: {
    light: "#e1d3bd",
    dark: "#ddc399"
  },
  black: {
    light: "#212529",
    base: "#000000"
  },
  accent: {
    light: "#5bbac9",
    base: "#5fd1c5"
  },
  gray: {
    light: "#f8f8f8",
    medium: "#f2f4f4",
    base: "#aab3b6",
    bg: "#f2f5f7"
  },
  blue: {
    light: "#d6f8ff",
    medium: "#8be7ff",
    base: "#0a8eb1",
    dark: "#0b718d",
    darkest: "#00252f",
    50: "#e6f3f7",
    100: "#b5dde7",
    150: "#dcedf2",
    200: "#84c6d8",
    300: "#53afc8",
    400: "#3aa4c0",
    500: "#0a8eb1",
    600: "#097f9f",
    700: "#07637b",
    800: "#054758",
    900: "#00252f"
  },
  red: {
    light: "#c0525d",
    dark: "#c32727",
    darkest: "#ed5555"
  },
  green: {
    base: "#24c178",
    light: "#4fdb9a",
    dark: "#0b8d50"
  }
};
