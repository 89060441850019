import { chakra, useBreakpointValue } from "@chakra-ui/react";
import PropTypes from "prop-types";

import { SIDE_NAV_WIDTH } from "components/layout/constants";
import Loader from "components/shared/loader/loader";

const LoaderBox = chakra(Loader);

const PageLoaderWrapper = ({ children, isLoaded }) => {
  const offset = useBreakpointValue({
    base: {},
    lg: {
      left: SIDE_NAV_WIDTH,
      width: `calc(100vw - ${SIDE_NAV_WIDTH})`
    }
  });

  return isLoaded ? <>{children}</> : <LoaderBox type="fullPage" {...offset} isLoadingTextVisible isVisible />;
};

PageLoaderWrapper.propTypes = {
  children: PropTypes.node,
  isLoaded: PropTypes.bool.isRequired
};

PageLoaderWrapper.defaultProps = {
  children: null
};

export default PageLoaderWrapper;
