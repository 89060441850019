import React from "react";

import { Text } from "ui-kit";

export const SideNavAreaName = props => (
  <Text
    as="li"
    textStyle="body"
    fontSize="sm"
    color="gray.base"
    pl="3"
    lineHeight="tall"
    listStyleType="none"
    {...props}
  />
);
