import {
  Checkbox as ChakraCheckbox,
  CheckboxGroup as ChakraCheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack
} from "@chakra-ui/react";
import { forwardRef } from "react";

/**
 * @type {typeof ChakraCheckbox}
 */
export const FormCheckbox = forwardRef((props, ref) => {
  const { label, labelProps = {}, name, value, onChange, isInvalid, isRequired, errorMessage, ...rest } = props;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <ChakraCheckbox
        ref={ref}
        id={props.id ?? name}
        name={name}
        value={value}
        onChange={onChange}
        borderColor="primary"
        {...rest}
      >
        <FormLabel htmlFor={props.id ?? name} mt="2" fontSize="sm" fontFamily="bodyBold" {...labelProps}>
          {label}
        </FormLabel>
      </ChakraCheckbox>

      <FormErrorMessage pl="0" mt="0">
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
});

/**
 * @type {typeof ChakraCheckboxGroup}
 */
export const FormCheckboxGroup = forwardRef((props, ref) => {
  const { name, value, onChange, isInvalid, isRequired, errorMessage, children, ...rest } = props;

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <Stack spacing={{ sm: 1, md: 5 }} direction={{ sm: "column", md: "row" }}>
        <ChakraCheckboxGroup ref={ref} id={props.id ?? name} name={name} value={value} onChange={onChange} {...rest}>
          {children}
        </ChakraCheckboxGroup>
      </Stack>
      {isInvalid && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
});
