import { Badge } from "@chakra-ui/react";

const BannerOnImage = ({ text, position = "top", variant = "outline", colorScheme = "gray" }) => {
  return (
    <Badge
      position="absolute"
      fontSize="14px"
      zIndex={3}
      px="10px"
      ml={2}
      mt={2}
      lineHeight="24px"
      colorScheme={colorScheme}
      variant={variant}
      left="1px"
      {...(position === "top" ? { top: "1px" } : { bottom: "9px" })}
    >
      {text}
    </Badge>
  );
};

export default BannerOnImage;
