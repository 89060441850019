import { ACTION_TYPES } from "constants/action-types";

const initialState = {
  auth: [],
  listings: [],
  user: []
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ACTION_TYPES.ERROR.SET_ERROR:
      return { ...state, [payload.type]: payload.errors };

    case ACTION_TYPES.ERROR.UNSET_ERROR:
      return { ...state, [payload.type]: [] };

    default:
      return state;
  }
}
