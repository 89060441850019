import { Box } from "@chakra-ui/react";
import React, { useContext, useMemo } from "react";
import { useContainerQuery } from "react-container-query";

import { pick, sortBy } from "lodash";
import { ResponsiveContext } from "./context";

/**
 * @typedef {"base" | "sm" | "md" | "lg" | "xl" | string} BreakpointName
 *
 * @typedef {{
 *   minWidth?: string | number,
 *   maxWidth?: string | number,
 *   minHeight?: string | number,
 *   maxHeight?: string | number
 * } | string | number} Query
 *
 * @typedef {{ [key: BreakpointName]: Query }} Breakpoints
 */

/**
 * @type Breakpoints
 */
const DEFAULT_BREAKPOINTS = {
  base: 0,
  sm: 300,
  md: 450,
  lg: 600,
  xl: 750
};

/**
 * @returns {{
 *  valueFor: () => string | unknown,
 *  [key: BreakpointName]: boolean
 * }}
 */
export const useContainerBreakpoints = () => {
  const c = useContext(ResponsiveContext);
  if (!c === undefined) {
    throw new Error("Use useContainerBreakpoints() within a <ResponsiveContainer> element");
  }
  return c.breakpoints;
};

export const useContainerBreakpointsConfig = () => {
  const c = useContext(ResponsiveContext);
  if (!c === undefined) {
    throw new Error("Use useContainerBreakpoints() within a <ResponsiveContainer> element");
  }
  return c.config;
};

export const useContainerValue = (config = {}) => {
  const breakpoints = useContainerBreakpoints();
  const breakpointsConfig = useContainerBreakpointsConfig();

  const validEntries = useMemo(() => {
    return pick(breakpointsConfig, Object.keys(config));
  }, [breakpointsConfig, config]);

  return useMemo(() => {
    const entries = sortBy(Object.entries(validEntries) ?? [], [1]).reverse();
    const matchKey = entries.map(([key]) => key).find(key => breakpoints[key] === true) ?? [];
    const value = config[matchKey] ?? config.default ?? config.base;
    return value;
  }, [config, breakpoints, validEntries]);
};

/**
 *
 * @param {React.FC} Component
 * @param {Breakpoints} [breakpoints]
 * @returns {React.ReactElement}
 */
export const withContainerBreakpoints = (Component, breakpoints) => {
  const WrappedComponent = props => {
    return (
      <ResponsiveContainer breakpoints={breakpoints}>
        <Component {...props} />
      </ResponsiveContainer>
    );
  };

  WrappedComponent.displayName = `Responsive${Component.displayName ?? Component.name ?? "Component"}`;

  return WrappedComponent;
};

/**
 * @type {React.FC<{ breakpoints: Breakpoints }> & typeof Box}
 */
export const ResponsiveContainer = React.memo(
  ({ breakpoints: breakpointsParam = DEFAULT_BREAKPOINTS, children, ...rest }) => {
    const queryWithDefaults = useMemo(() => {
      if (breakpointsParam) {
        return Object.keys(breakpointsParam).reduce((prev, key) => {
          const b = breakpointsParam[key];
          if (typeof b === "object") {
            prev[key] = b;
          } else {
            prev[key] = { minWidth: b };
          }

          return prev;
        }, {});
      }

      return DEFAULT_BREAKPOINTS;
    }, [breakpointsParam]);

    const [breakpoints, ref] = useContainerQuery(queryWithDefaults);

    const value = useMemo(() => {
      return {
        breakpoints,
        config: breakpointsParam
      };
    }, [breakpoints, breakpointsParam]);

    return (
      <Box ref={ref} w="full" {...rest}>
        <ResponsiveContext.Provider value={value}>{children}</ResponsiveContext.Provider>
      </Box>
    );
  }
);

ResponsiveContainer.displayName = "ResponsiveContainer";
