import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Box, Stack, Flex } from "@chakra-ui/react";
import { Icon } from "ui-kit";
import { Chevron } from "ui-kit/icon";

import { ArrowButton, NumberButton } from "./buttons";

const translateX = {
  sm: "3px",
  md: "5px",
  lg: "8px"
};

const Pagination = ({ totalPages, pagesToShow, onGoToPage, currentPage, size = "md" }) => {
  const pagesToShowMid = Math.floor(pagesToShow / 2);
  const firstPage =
    currentPage > pagesToShowMid ? Math.min(currentPage - pagesToShowMid, totalPages - pagesToShow + 1 || 1) : 1;
  const lastPage = firstPage + pagesToShow - 1 <= totalPages ? firstPage + pagesToShow - 1 : totalPages;
  const show3Dots = totalPages - lastPage >= 2;
  const showTotalPages = show3Dots || lastPage + 1 === totalPages;

  const isPrevDisabled = currentPage <= 1 || totalPages === 1;
  const isNextDisabled = currentPage >= totalPages || totalPages === 1;

  const pages = useMemo(() => {
    let pagesArray = Array.from({ length: totalPages }, (_, i) => i + 1);
    pagesArray = pagesArray.slice(firstPage - 1, lastPage);
    pagesArray = show3Dots ? [...pagesArray, "..."] : pagesArray;
    pagesArray = showTotalPages ? [...pagesArray, totalPages] : pagesArray;

    return pagesArray;
  }, [totalPages, firstPage, lastPage, show3Dots, showTotalPages]);

  return (
    <Box as="nav" role="navigation" aria-label="Pagination Navigation">
      <Stack isInline as="ol" spacing={1}>
        <Flex as="li">
          <ArrowButton
            isDisabled={isPrevDisabled}
            onClick={() => onGoToPage(1)}
            size={size}
            aria-label="Go to the first page"
            icon={
              <>
                <Icon as={Chevron} transform={`rotate(180deg) translateX(-${translateX[size]})`} size={size} />
                <Icon as={Chevron} transform={`rotate(180deg) translateX(${translateX[size]})`} size={size} />
              </>
            }
          />
        </Flex>
        <Flex as="li">
          <ArrowButton
            isDisabled={isPrevDisabled}
            onClick={() => onGoToPage(currentPage - 1)}
            size={size}
            aria-label="Go to the previous page"
            icon={<Icon as={Chevron} transform="rotate(180deg)" size={size} />}
          />
        </Flex>
        {pages.map(page => (
          <Flex as="li" key={page}>
            {page === "..." ? (
              <NumberButton
                size={size}
                // Don't have the 3 dots do anything.
                pointerEvents="none"
                aria-hidden="true"
              >
                {page}
              </NumberButton>
            ) : (
              <NumberButton
                onClick={() => onGoToPage(page)}
                size={size}
                aria-label={`Go to page ${page}`}
                isActive={currentPage === page}
                {...(currentPage === page ? { "aria-current": true } : {})}
              >
                {page}
              </NumberButton>
            )}
          </Flex>
        ))}
        <Flex as="li">
          <ArrowButton
            isDisabled={isNextDisabled}
            onClick={() => onGoToPage(currentPage + 1)}
            size={size}
            aria-label="Go to the next page"
            icon={<Icon as={Chevron} size={size} />}
          />
        </Flex>
        <Flex as="li">
          <ArrowButton
            isDisabled={isNextDisabled}
            onClick={() => onGoToPage(totalPages)}
            size={size}
            aria-label="Go to the last page"
            icon={
              <>
                <Icon as={Chevron} transform={`translateX(${translateX[size]})`} size={size} />
                <Icon as={Chevron} transform={`translateX(-${translateX[size]})`} size={size} />
              </>
            }
          />
        </Flex>
      </Stack>
    </Box>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  pagesToShow: PropTypes.number,
  onGoToPage: PropTypes.func,
  currentPage: PropTypes.number
};

Pagination.defaultProps = {
  pagesToShow: 3,
  onGoToPage: () => true,
  currentPage: 0
};

export default Pagination;
