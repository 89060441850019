import { ACTION_TYPES } from "constants/action-types";

import mapBrands from "mappers/brands";

const initialState = {
  status: {},
  data: { asArray: [], byID: {} }
};

export default function brandsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.BRANDS.SET_STATUS:
      return { ...state, status: action.payload };
    case ACTION_TYPES.BRANDS.SET_DATA:
      return { ...state, data: mapBrands(action.payload) };
    default:
      return state;
  }
}
