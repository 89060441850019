import { Container, Image, Heading, Text, VStack } from "@chakra-ui/react";
import { LogoNoSwap } from "static/images";
import { Background } from "components/shared/background";

const MaintenanceAlert = () => {
  const RELOAD_INTERVAL = 60_000; // 1 minute
  return (
    <VStack onLoad={window.setTimeout(() => window.location.reload(), RELOAD_INTERVAL)}>
      <Background zIndex="base" />
      <Image src={LogoNoSwap} alt="Max Retail logo" maxWidth="400px" paddingTop="2em" />
      <Container pos="relative" zIndex="docked">
        <Heading as="h1" align="center">
          System Maintenance
        </Heading>

        <Text textStyle="body" align="center" margin="2em" maxWidth="800px">
          <p>Our system is undergoing maintenance, and cannot currently process your request.</p>
          <p>Sorry for the inconvenience. Please try reloading the page in a few minutes.</p>
        </Text>
      </Container>
    </VStack>
  );
};

export default MaintenanceAlert;
