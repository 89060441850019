import { forwardRef, useMemo } from "react";

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input as ChakraInput,
  InputGroup,
  Tooltip,
  VisuallyHidden
} from "@chakra-ui/react";
import { Icon } from "ui-kit";
import { CircleInfo } from "ui-kit/icon";

const Input = forwardRef((props, ref) => {
  const {
    label,
    labelProps = {},
    hideLabel = false,
    name,
    helperText,
    value,
    onChange,
    onBlur,
    isInvalid,
    isRequired,
    required = false,
    errorMessage,
    inputRightElement,
    inputHelpText,
    ...rest
  } = props;

  const formLabel = useMemo(() => {
    if (hideLabel) {
      return (
        <VisuallyHidden>
          <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
            {label}
          </FormLabel>
        </VisuallyHidden>
      );
    }

    return (
      <HStack justify="space-between">
        <FormLabel htmlFor={props.id ?? name} fontSize="sm" fontFamily="bodyBold" {...labelProps}>
          {label}
        </FormLabel>
        {inputHelpText && (
          <Tooltip placement="auto" label={inputHelpText} hasArrow shouldWrapChildren>
            <Icon icon={CircleInfo} size="sm" d="block" variant="primary" cursor="pointer" mr="2" />
          </Tooltip>
        )}
      </HStack>
    );
  }, [props.id, name, hideLabel, inputHelpText, label]);

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      {formLabel}
      {inputRightElement ? (
        <InputGroup>
          <ChakraInput
            ref={ref}
            pl="4"
            id={props.id ?? name}
            name={name}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            required={required}
            {...rest}
          />
          {inputRightElement}
        </InputGroup>
      ) : (
        <ChakraInput
          ref={ref}
          pl="4"
          id={props.id ?? name}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          required={required}
          {...rest}
        />
      )}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage pl="1.3em">{errorMessage}</FormErrorMessage>
    </FormControl>
  );
});

export default Input;
