import { mode } from "@chakra-ui/theme-tools";

export default {
  baseStyle: props => ({
    backgroundColor: mode("white", "gray.700")(props),
    borderRadius: 8,
    padding: 3,
    shadow: "0px 3px 6px rgb(0, 0, 0 / 29%)"
  }),
  sizes: {
    sm: {
      shadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
    },
    md: {
      shadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
    },
    lg: {
      shadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
    }
  },
  defaultProps: {
    size: "sm"
  }
};
