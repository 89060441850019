import React, { useState } from "react";
import dayjs from "dayjs";
import { Image, Box } from "@chakra-ui/react";

import { pendingImage, placeholder } from "static/images";
import BannerOnImage from "../banner-on-image/banner-on-image";

/**
 * @type {React.FC<{
 *  className?: string,
 * src: string,
 * errorSrc: any,
 * fallbackSrc: any,
 * dateCreated?: string,
 * displayDaysListed?: boolean }>}
 */
const LazyBackgroundImage = ({
  src,
  errorSrc = pendingImage,
  fallbackSrc = placeholder,
  dateCreated,
  displayDaysListed = false,
  condition = "",
  ...rest
}) => {
  const [imgSrc, setImgSrc] = useState(() => src);

  const daysAgo = dayjs().diff(dayjs(dateCreated), "day");
  const daysAgoText = daysAgo === 0 ? "listed today" : `${daysAgo} day${daysAgo > 1 ? "s" : ""} listed`;
  let daysAgoColorScheme = "gray";
  if (daysAgo > 90) {
    daysAgoColorScheme = "red";
  } else if (daysAgo > 60) {
    daysAgoColorScheme = "yellow";
  }

  return (
    <Box height="100%">
      <Image src={imgSrc} fallbackSrc={fallbackSrc} onError={() => setImgSrc(errorSrc)} loading="lazy" {...rest} />
      {displayDaysListed && <BannerOnImage text={daysAgoText} colorScheme={daysAgoColorScheme} variant="subtle" />}
      {condition?.toLowerCase() === "preloved" && (
        <BannerOnImage colorScheme="purple" text={condition} position="bottom" variant="subtle" />
      )}
    </Box>
  );
};

export default LazyBackgroundImage;
