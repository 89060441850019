import {
  belkLogo,
  bronzeCircle,
  diaLogo,
  diamondCircle,
  ebayLogo,
  giltLogo,
  goldCircle,
  grailedLogo,
  mercariLogo,
  notificationBlueBg,
  notificationRedBg,
  platinumCircle,
  poshmarkLogo,
  queenlyLogo,
  so5Logo,
  spoLogo,
  theBayLogo,
  tiendamiaLogo,
  verishopLogo,
  walmartLogo,
  yaysayLogo,
  reitmansLogo,
  rwCoLogo,
  pennLogo
} from "static/images";

export const TOOLTIPS = {
  approval_rate: {
    negative:
      "Buyers expect everything listed to be available for immediate purchase. Retailers must maintain a 98% or above approval rating to sell on Max Retail.",
    neutral:
      "Buyers expect everything listed to be available for immediate purchase. Retailers must maintain a 98% or above approval rating to sell on Max Retail.",
    positive:
      "The higher your approval rating, the more opportunity for sales! Keep your order approval rating above 98% so you are automatically added to new partner channels."
  },
  approval_time_avg: {
    negative:
      "Everyone loves a quick response time! The faster you approve, the faster you can ship, the quicker you get paid.",
    neutral:
      "Everyone loves a quick response time! The faster you approve, the faster you can ship, the quicker you get paid.",
    positive:
      "Everyone loves a quick response time! The faster you approve, the faster you can ship, the quicker you get paid."
  },
  inventory_last_updated_ago: {
    negative:
      "If they see it, they want it! Buyers expect everything listed to be available for immediate purchase. Retailers must maintain a 98% or above approval rating to sell on Max Retail.",
    neutral:
      "If they see it, they want it! Buyers expect everything listed to be available for immediate purchase. Retailers must maintain a 98% or above approval rating to sell on Max Retail.",
    positive:
      "Buyers expect everything listed to be available for immediate purchase. Make sure your inventory is up-to-date."
  },
  ship_time_avg: {
    negative: "Who doesn’t love receiving their orders fast! Max Retail pays out when orders are shipped.",
    neutral: "Who doesn’t love receiving their orders fast! Max Retail pays out when orders are shipped.",
    positive: "Everyone loves receiving their orders fast!  Max Retail pays out when orders are shipped."
  }
};

export const LIST_MORE_BUTTON_TEXT = "List More!";

export const HERO_MESSAGES = {
  addInventory: {
    buttonLink: "/addListing",
    buttonText: "Add Inventory!"
  },
  learnMore: {
    buttonLink: "//www.maxretail.com/max-retail-retailer-resources",
    buttonText: "Learn More"
  },
  approveOrder: {
    buttonLink: "/orders/pending/sales",
    buttonText: "Approve Orders!"
  },
  shipNow: {
    buttonLink: "/orders/approved/sales",
    buttonText: "Ship Now!"
  },
  listMore: {
    buttonLink: "/addListing",
    buttonText: LIST_MORE_BUTTON_TEXT
  }
};

export const TOP_NOTIFICATIONS = {
  payoutNeeded: {
    priority: 1,
    buttonLink: "/settings/payout",
    buttonText: "Set Up Payout",
    mode: "red"
  },
  noResaleId: {
    priority: 2,
    buttonLink: "/",
    buttonText: "Upload Resale ID",
    mode: "red"
  },
  approveNow: {
    priority: 3,
    buttonLink: "/orders/pending/sales",
    buttonText: "Approve Now",
    buttonProps: {
      color: "#0A8EB1"
    },
    mode: "blue"
  },
  shipNow: {
    priority: 4,
    buttonLink: "/orders/approved/sales",
    buttonText: "Ship Now",
    mode: "red"
  },
  updateInventory: {
    priority: 5,
    buttonLink: "/mylistings",
    buttonText: "Update Inventory",
    buttonProps: {
      color: "#0A8EB1"
    },
    mode: "blue"
  }
};

export const NOTIFICATION_BG_IMAGES = {
  red: notificationRedBg,
  blue: notificationBlueBg
};

export const SALES_CHANNELS = {
  RUE_GILT: "Rue Gilt",
  SAKS_OFF_FIFTH: "Saksoff5th",
  EBAY: "ebay",
  SPO: "SPO",
  WALMART: "Walmart",
  THE_BAY: "The Bay",
  POSHMARK: "Poshmark",
  MERCARI: "mercari",
  GRAILED: "Grailed",
  POSH_PRELOVED: "PoshPreloved",
  VERISHOP: "Verishop",
  BELK: "Belk",
  DIA_CO: "Dia & Co",
  YAYSAY: "YaySay",
  QUEENLY: "Queenly",
  TIENDAMIA: "Tiendamia",
  REITMANS: "Reitmans",
  RW_CO: "RW & Co",
  PENNINGTONS: "Penningtons"
};
export const SALES_CHANNELS_TOOLTIPS = {
  [SALES_CHANNELS.THE_BAY]: {
    logo: theBayLogo
  },
  [SALES_CHANNELS.POSHMARK]: {
    logo: poshmarkLogo
  },
  [SALES_CHANNELS.GRAILED]: {
    logo: grailedLogo
  },
  [SALES_CHANNELS.SPO]: {
    logo: spoLogo
  },
  [SALES_CHANNELS.RUE_GILT]: {
    logo: giltLogo
  },
  [SALES_CHANNELS.EBAY]: {
    logo: ebayLogo
  },
  [SALES_CHANNELS.SAKS_OFF_FIFTH]: {
    logo: so5Logo
  },
  [SALES_CHANNELS.WALMART]: {
    logo: walmartLogo
  },
  [SALES_CHANNELS.MERCARI]: {
    logo: mercariLogo
  },
  [SALES_CHANNELS.QUEENLY]: {
    logo: queenlyLogo
  },
  [SALES_CHANNELS.BELK]: {
    logo: belkLogo
  },
  [SALES_CHANNELS.DIA_CO]: {
    logo: diaLogo
  },
  [SALES_CHANNELS.YAYSAY]: {
    logo: yaysayLogo
  },
  [SALES_CHANNELS.VERISHOP]: {
    logo: verishopLogo
  },
  [SALES_CHANNELS.TIENDAMIA]: {
    logo: tiendamiaLogo
  },
  [SALES_CHANNELS.REITMANS]: {
    logo: reitmansLogo,
    height: "20px",
    marginTop: "5px"
  },
  [SALES_CHANNELS.RW_CO]: {
    logo: rwCoLogo,
    height: "20px",
    marginTop: "5px"
  },
  [SALES_CHANNELS.PENNINGTONS]: {
    logo: pennLogo,
    height: "20px",
    marginTop: "5px"
  },
  default: {
    description: "Maximize your exposure by maintaining a high approval rate."
  }
};

export const TIER_ASSETS = {
  diamond: {
    name: "Diamond Exposure",
    tierCount: 4,
    tagMessage: "100% Order Approval",
    salesChannels: [
      SALES_CHANNELS.EBAY,
      SALES_CHANNELS.SAKS_OFF_FIFTH,
      SALES_CHANNELS.VERISHOP,
      SALES_CHANNELS.RUE_GILT,
      SALES_CHANNELS.BELK
    ],
    circleImage: diamondCircle,
    bannerMessage:
      "Congrats!\nYour last 30 days of performance unlocked Diamond Exposure!\nKeep your inventory up to date and approve orders promptly to remain there!",
    gradient: "transparent linear-gradient(180deg, #A5C4CD 0%, #E9CE6A 49%, #D9D1C1 100%) 0% 0% no-repeat padding-box"
  },
  platinum: {
    name: "Platinum Exposure",
    tierCount: 3,
    tagMessage: "98% Order Approval",
    salesChannels: [
      SALES_CHANNELS.SPO,
      SALES_CHANNELS.THE_BAY,
      SALES_CHANNELS.WALMART,
      SALES_CHANNELS.DIA_CO,
      SALES_CHANNELS.TIENDAMIA,
      SALES_CHANNELS.REITMANS,
      SALES_CHANNELS.PENNINGTONS,
      SALES_CHANNELS.RW_CO
    ],
    circleImage: platinumCircle,
    bannerMessage:
      "Congrats!\nYour performance over the last 30 days unlocked Platinum Exposure.\nGet your order approval rating to 100% to unlock Diamond Exposure!",
    gradient: "transparent linear-gradient(180deg, #A5C4CD 0%, #E9CE6A 49%, #D9D1C1 100%) 0% 0% no-repeat padding-box"
  },
  gold: {
    name: "Gold Exposure",
    tierCount: 2,
    tagMessage: "95% Order Approval",
    salesChannels: [SALES_CHANNELS.POSHMARK, SALES_CHANNELS.YAYSAY, SALES_CHANNELS.QUEENLY],
    circleImage: goldCircle,
    bannerMessage:
      "Congrats!\nYour performance over the last 30 days unlocked Gold Exposure.\nGet your order approval rating to 98% to unlock Platinum Exposure!",
    gradient: "transparent linear-gradient(180deg, #E9CE6A 0%, #EDD3A9 49%, #BCA888 100%) 0% 0% no-repeat padding-box"
  },
  bronze: {
    name: "Bronze Exposure",
    tierCount: 1,
    tagMessage: "< 95% Order Approval",
    salesChannels: [SALES_CHANNELS.MERCARI, SALES_CHANNELS.GRAILED],
    circleImage: bronzeCircle,
    bannerMessage:
      "Your order approval rating is below expectation. Increase your order approval rating to 95% or higher to unlock more exposure.",
    gradient: "transparent linear-gradient(180deg, #F0B961 0%, #965700 49%, #845C1D 100%) 0% 0% no-repeat padding-box"
  },
  default: {
    tierCount: 0,
    bannerMessage:
      "Your account is paused because of your performance over the last 30 days.\nComplete an onboarding call to reactivate your account.",
    gradient: "transparent linear-gradient(180deg, #A97828 0%, #4A300D 49%, #966822 100%) 0% 0% no-repeat padding-box"
  }
};

export const TIERS = {
  PLATINUM: "platinum",
  GOLD: "gold",
  BRONZE: "bronze",
  DIAMOND: "diamond",
  DEFAULT: "default"
};

export const THERMOSTAT_HEIGHT = "775px";
export const EXTERNAL_MEETING_URL = "//meetings.hubspot.com/max-retail/book-a-meeting-general-calendar";
