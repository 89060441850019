import { Box, Flex, IconButton, Image, useBreakpointValue } from "@chakra-ui/react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LogoNoSwap } from "static/images";

// layout components
import { MOBILE_NAV_HEIGHT, SIDE_NAV_WIDTH } from "components/layout/constants";
import InternetExplorerAlert from "components/layout/internet-explorer-alert";

export const MobileNavbar = ({ onOpen = () => {} }) => {
  const isVisible = useBreakpointValue({ base: true, lg: false });

  if (!isVisible) {
    return null;
  }

  return (
    <Flex
      justify="center"
      bg="white"
      shadow="lg"
      zIndex="sticky"
      p="5"
      pos="fixed"
      top={0}
      w="100vw"
      h={MOBILE_NAV_HEIGHT}
    >
      <IconButton
        onClick={onOpen}
        variant="unstyled"
        position="absolute"
        top="50%"
        transform="translateY(-50%)"
        left="20px"
      >
        <FontAwesomeIcon icon={faBars} className="fa-2x" color="blue.darkest" />
      </IconButton>
      <Image src={LogoNoSwap} loading="eager" alt="Max Retail logo" maxWidth="150px" margin="auto" />
    </Flex>
  );
};

export const ScrollableContent = ({ children }) => {
  return (
    <Box flexGrow="1" pt={{ lg: 0 }}>
      <InternetExplorerAlert />
      {children}
    </Box>
  );
};

export const StyleMainContentContainer = ({ children }) => {
  return (
    <Box
      as="main"
      bgColor="gray.bg"
      minH="100vh"
      w={{ lg: `calc(100vw - ${SIDE_NAV_WIDTH})` }}
      ml={{ lg: SIDE_NAV_WIDTH }}
      position="relative"
    >
      <Flex direction="column">{children}</Flex>
    </Box>
  );
};

export const StyleLayoutContainer = props => {
  return <Box h="100vh" maxW="100vw" {...props} />;
};

export const StyleSideNavContainer = ({ isOpen = true, children }) => {
  return (
    <Box
      as="aside"
      d={{ base: isOpen ? "block" : "none", lg: "initial" }}
      w="300px"
      shadow="lg"
      position="fixed"
      width={SIDE_NAV_WIDTH}
      bg="white"
      minH="100vh"
      top="0"
      left="0"
      bottom="0"
      zIndex={isOpen ? "popover" : "banner"}
      overflowY="auto"
    >
      {children}
    </Box>
  );
};
