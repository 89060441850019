import latestVersionAPI from "api/latest-version-api";
import { useEffect, useRef, useState } from "react";

export const useVersionService = () => {
  const [shouldReload, setShouldReload] = useState(false);

  const sourceVersion = useRef();
  const interval = useRef();

  useEffect(() => {
    const runUseEffect = async () => {
      const { latestReleaseTimestamp } = await latestVersionAPI.fetchLatestVersion();
      sourceVersion.current = new Date(latestReleaseTimestamp).getTime();
    };

    if (!interval.current && process.env.NODE_ENV !== "development") {
      interval.current = setInterval(async () => {
        const { latestReleaseTimestamp: intervalLatestReleaseTimestamp } = await latestVersionAPI.fetchLatestVersion();

        if (sourceVersion.current && intervalLatestReleaseTimestamp > sourceVersion.current) {
          setShouldReload(true);
        } else {
          setShouldReload(false);
        }
      }, 5 * 60 * 1000); // 5 minutes
    }

    runUseEffect();
    return () => clearInterval(interval.current);
  }, []);

  return shouldReload;
};
