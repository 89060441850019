export const WINDOW_WIDTH_TABLET = 991;
export const WINDOW_WIDTH_MOBILE = 767;

export const FULL_WIDTH_PAGES = [
  "/*",
  "/full",
  "/browse",
  "/browse/",
  "/browse/*",
  "/listing/buy/*",
  "/listing/sell/*",
  "/listing/update/*",
  "/request/update/*",
  "/mylistings",
  "/orders",
  "/orders/*",
  "/listings",
  "/listings/*",
  "/listings/brand/*",
  "/listings/retailer/*",
  "/requests/brand/*"
];

export const PAGES_WITHOUT_STICKY = ["/listing/add/beta", "/verifications"];
export const PAGES_WITH_STICKY_ON_SCROLL = {
  "/": {
    desktop: 535,
    mobile: 900
  }
};
