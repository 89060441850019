import ApiHandler from "api/api-handler";
import { API_ROUTES } from "api/const";

const flagsApi = {
  getFlags: (params = {}) =>
    ApiHandler.get({
      url: API_ROUTES.FLAGS,
      params
    }),

};

export default flagsApi;
