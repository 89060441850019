/*
  IN: nestedObject: {}, pathArray: [string]
  OUT: *
  DESC: Returns the value corresponding to an object's nested keys, useful for states or constants

  EXAMPLE:
    let nestedObject = { foo: {bar: 'baz'}};

    getObjectValueByPath(nestedObject, ['foo', 'bar']); // Will return 'baz'
*/

import { WINDOW_URL } from "constants/app";
import { WINDOW_WIDTH_MOBILE } from "constants/layout/layout";
import { isNaN } from "lodash";
import { DEFAULT_LOCALE } from "shared/formatting";

export const getObjectValueByPath = (nestedObject, pathArray) => pathArray.reduce((o, i) => o[i], nestedObject);

export const usingInternetExplorer = () => {
  return navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1;
};

export const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toCurrency = (value, { locale = DEFAULT_LOCALE, options = {} } = {}) => {
  const formatter = new Intl.NumberFormat(locale, { style: "currency", currency: "USD", ...options });

  const parsedValue = Number.parseFloat(value);

  if (isNaN(parsedValue)) {
    return formatter.format(0);
  }

  return formatter.format(parsedValue);
};

export const toPercent = (value, { locale = DEFAULT_LOCALE, options = {} } = {}) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: "percent",
    unitDisplay: "narrow",
    maximumFractionDigits: 0,
    ...options
  });

  if (isNaN(value)) {
    return formatter.format(0);
  }

  return formatter.format(value);
};

export const roundToCents = value => {
  const PRECISION = 100;
  return Math.round(value * PRECISION) / PRECISION;
};

export const getSelectOptions = array => {
  return array.map(item => ({ value: item.display_brand_name, label: item.display_brand_name, id: item.id }));
};

export const getScrollTop = () => {
  const doc = document.documentElement;
  return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
};

export const getIsMobile = () => window.innerWidth <= WINDOW_WIDTH_MOBILE;

export const getFilterParams = (filters = [], type) => {
  let params = "";
  if (filters.length > 0 && type === "size")
    filters.forEach(filter => {
      params += `&${type}=${filter.label.toUpperCase()}`;
    });
  else if (type === "price") params = `&${type}_min=${filters[0]}&${type}_max=${filters[1]}`;
  else
    filters.forEach(filter => {
      if (filter.value === "all") params = "";
      else params += `&${type}=${filter.value}`;
    });
  return params;
};

export const getIsArrayEqual = (arr1, arr2) => {
  return arr1.every((val, index) => val === arr2[index]);
};

export const escapeRegExp = text => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const getRandomElement = (list = []) => {
  return list[Math.floor(Math.random() * (list.length - 0) + 0)];
};

// TODO: clean up this after March 1st and related checks.
export const showTopBanner = new Date("March 1, 2022 00:00:00") > new Date();

export const getSortedArray = (rawData, key) => {
  return rawData.sort((item1, item2) => item1[key].localeCompare(item2[key]));
};

export const asyncTimeout = milliseconds => {
  return new Promise(resolve => {
    setTimeout(() => resolve(), milliseconds);
  });
};

export const getImagePreview = file => {
  return WINDOW_URL.createObjectURL(file);
};

export const revokeImagePreview = file => {
  return WINDOW_URL.revokeObjectURL(file);
};

export const base64ToFile = (base64String, fileName = "image.jpg") => {
  // Convert base64 to binary
  const byteString = atob(base64String);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  // Create a Blob from the ArrayBuffer
  const blob = new Blob([ab], { type: "image/jpeg" });

  // Create a File object from the Blob
  const file = new File([blob], fileName, { type: "image/jpeg" });

  return file;
};

export const isValidUrl = urlString => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
