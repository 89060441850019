export default {
  sizes: {
    xs: { boxSize: "0.5em" },
    sm: { boxSize: "0.75em" },
    md: { boxSize: "1em" },
    lg: { boxSize: "1.5em" },
    xl: { boxSize: "2em" },
    xxl: { boxSize: "2.25em" }
  },
  variants: {
    light: { color: "white" },
    dark: { color: "blue.darkest" },
    active: { color: "primary" },
    red: { color: "secondary.red" },
    darkred: { color: "red.darkest" }
  },
  defaultProps: {
    variant: "dark",
    size: "md"
  }
};
