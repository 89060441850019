import React from "react";
import { Link } from "react-router-dom";

import { CartAPI } from "api/cart-api";

import PopupService from "shared/popup-service";

import { ACTION_TYPES } from "constants/action-types";
import STATUS_TYPES from "constants/status-types";
import { POPUP_TYPES } from "constants/popups.const";

import { trackTransactionCreated } from "shared/segment";
import { getUserData, getUserRetailerId } from "store/selectors/user";
import { getCartItemsByRetailer } from "store/selectors/cart";
import { setError } from "store/actions/errors";

export function setStatus(status) {
  return {
    type: ACTION_TYPES.CART.SET_STATUS,
    payload: status
  };
}

export function addItem(item, units) {
  PopupService.makeCartPopup(item);

  return {
    type: ACTION_TYPES.CART.ADD_ITEM,
    payload: { item, units }
  };
}

export function modifyItem(item, units) {
  return {
    type: ACTION_TYPES.CART.MODIFY_ITEM,
    payload: { item, units }
  };
}

export function removeItem(itemId) {
  return {
    type: ACTION_TYPES.CART.REMOVE_ITEM,
    payload: { itemId }
  };
}

export function doRemoveByRetailerId(itemId) {
  return {
    type: ACTION_TYPES.CART.REMOVE_BY_RETAILER_ID,
    payload: itemId
  };
}

export function clearCart() {
  return {
    type: ACTION_TYPES.CART.CLEAR_CART
  };
}

export function setTransaction(retailerId, data) {
  return {
    type: ACTION_TYPES.CART.SET_TRANSACTION,
    payload: { retailerId, data }
  };
}

export function doAddItem(item, units) {
  return async (dispatch, getState) => {
    await dispatch(setStatus(STATUS_TYPES.PENDING));

    const state = getState();
    const { provider } = item;
    const cartItems = getCartItemsByRetailer(state);
    const retailerId = getUserRetailerId(state);
    // drop the permissions
    const { permissions: _, ...user } = getUserData(state);

    const cartUnits = cartItems[provider]
      ? cartItems[provider].map(cartItem => ({
          listing: cartItem.id,
          sizes: cartItem.units
        }))
      : [];
    return CartAPI.createTransaction({
      provider: item.provider,
      receiver: retailerId,
      units: [...cartUnits, { listing: item.id, sizes: units }]
    }).then(
      async response => {
        await dispatch(addItem(item, units));
        await dispatch(setTransaction(provider, response));
        dispatch(setStatus(STATUS_TYPES.LOADED));
        trackTransactionCreated({ listing: item, user });
      },
      error => {
        const { data } = error;
        if (data.alreadytransaction) {
          /* eslint-disable max-len */
          const message = {
            // yourmove: "You requested this item and an order has already been created. Go to your Pending Orders to complete checkout.",
            yourmove: (
              <span>
                You requested this item and an order has already been created. Go to your{" "}
                <Link to="/orders/pending/purchases">Pending Orders</Link> to complete checkout.
              </span>
            ),
            theirmove:
              "You already requested this item from this retailer.  Please wait for them to approve or decline the transaction."
          };
          PopupService.makePopup(POPUP_TYPES.MESSAGE, message[data.alreadytransaction]);
          dispatch(setStatus(STATUS_TYPES.LOADED));
        } else {
          dispatch(setError({ type: "cart", errors: [data] }));
          dispatch(setStatus(STATUS_TYPES.FAILED));
        }
      }
    );
  };
}

export function doModifyItem(item, units) {
  return async (dispatch, getState) => {
    await dispatch(setStatus(STATUS_TYPES.PENDING));
    const { provider } = item;
    const state = getState();
    const cartItems = getCartItemsByRetailer(state);
    const retailerId = getUserRetailerId(state);
    // drop the permissions
    const { permissions: _, ...user } = getUserData(state);

    const cartUnits = cartItems[provider]?.map(cartItem => ({
      listing: cartItem.id,
      sizes: cartItem.id === item.id ? units : cartItem.units
    }));

    if (Object.keys(units).length !== 0) {
      return CartAPI.createTransaction({
        provider: item.provider,
        receiver: retailerId,
        units: cartUnits
      }).then(
        async response => {
          await dispatch(modifyItem(item, units));
          await dispatch(setTransaction(provider, response));
          dispatch(setStatus(STATUS_TYPES.LOADED));
          trackTransactionCreated({ listing: item, user });
        },
        error => {
          dispatch(setError({ type: "cart", errors: [error?.response?.data] }));
          dispatch(setStatus(STATUS_TYPES.FAILED));
        }
      );
    }
    return {};
  };
}

export function doRemoveItem(item) {
  return async (dispatch, getState) => {
    await dispatch(setStatus(STATUS_TYPES.PENDING));

    const { provider } = item;
    const state = getState();
    const cartItems = getCartItemsByRetailer(state);
    const retailerId = getUserRetailerId(state);
    // drop the permissions
    const { permissions: _, ...user } = getUserData(state);

    const cartUnits = cartItems[provider];
    const filteredCartUnits = cartUnits
      .filter(cartItem => cartItem.id !== item.id && Object.values(cartItem.units).length)
      .map(cartItem => ({
        listing: cartItem.id,
        sizes: cartItem.units
      }));

    if (!filteredCartUnits.length) {
      dispatch(setStatus(STATUS_TYPES.LOADED));
      dispatch(doRemoveByRetailerId(item.provider));

      return false;
    }

    if (cartUnits.length) {
      return CartAPI.createTransaction({
        provider: item.provider,
        receiver: retailerId,
        units: filteredCartUnits
      }).then(
        async response => {
          await dispatch(removeItem(item.id));
          await dispatch(setTransaction(provider, response));
          dispatch(setStatus(STATUS_TYPES.LOADED));
          trackTransactionCreated({ listing: item, user });
        },
        error => {
          dispatch(setError({ type: "cart", errors: [error?.response?.data] }));
          dispatch(setStatus(STATUS_TYPES.FAILED));
        }
      );
    }
    await dispatch(removeItem(item.id));
    await dispatch(setTransaction(provider, null));
    return dispatch(setStatus(STATUS_TYPES.LOADED));
  };
}
